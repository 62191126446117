// StudentSignUpPage.styles.js
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        maxWidth: '600px',
        margin: '0 auto',
    },
    input: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '100%',
        // align the text to the center and make uppercase, bold and bigger
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '16px',
        // add padding to the letters
        letterSpacing: '4px',
    },
    button: {
        padding: '10px 20px',
        margin: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#5667fd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    buttonSecondary: {
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        color: '#383838',
        cursor: 'pointer',
        background: 'none',
    },
    // Remove unused styles if they are not needed
}

export default styles
