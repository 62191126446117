import React from 'react'

function PrivacyPolicy() {
    return (
       <div className='text-white md:mt-[120px] sm:mt-[70px] mt-[40px] max-w-[740px] md:text-[22px] sm:text-lg text-base mb-16'>
    <h1 className='font-lilita md:text-6xl sm:text-5xl text-3xl mb-12'>Privacy Policy</h1>
    
    <p className='pb-8'>Last revised at: Aug. 13th, 2024</p>
    
    <p className='pb-8'>1. What is Joyed.ai?</p>
    <p className='pb-8'>Joyed.ai is a platform for practicing homework, motivated by setting student's own study goals and mild gamification.</p>
    <p className='pb-8'>Students create accounts and complete a survey for content personalization, then they need to be added by a tutor, who then creates assignments for them. Tutors can use Joyed to give engaging assignments for homework. Joyed offers an AI-assisted way to quickly create study content.</p>
    
    <p className='pb-8'>2. What is the purpose of this Privacy Policy?</p>
    <p className='pb-8'>This Privacy Policy is the document where we lay out for you the following:</p>
    <p className='pb-8'>
        - What kind of data do we collect<br/>
        - How do we collect it<br/>
        - Why do we collect it<br/>
        - How do we use it<br/>
        - Who has access to it<br/>
        - How are you able to request access and removal of your data<br/>
        - How to contact us or data protection authorities
    </p>
    <p className='pb-8'>Based on this information you may decide whether you want to use Joyed or not. We are committed to following the rules laid out in this document. We only ask you to accept these rules and principles of how we collect and process your personal data. You accept by creating an account. If you are unhappy with some of the Privacy Policy, you unfortunately cannot use Joyed.</p>

    <p className='pb-8'>3. What personal data does Joyed collect from me and why?</p>
    <p className='pb-8'>Personal data or personal information is any information relating to you that can identify you, directly or indirectly. Our platform is accessed through accounts. Having an account helps you keep track of your progress, personalize your own experience, save settings for multiple visits and compare your progress with your previous results.</p>
    <p className='pb-8'>If you are a tutor, an account is necessary to keep track of the progress your student has made. Collecting some personal data is therefore necessary for people to use Joyed as intended.</p>

    <p className='pb-8'>3.1 What are the main principles of Joyed's data collection policy?</p>
    <p className='pb-8'>We follow that all data collection and processing must be fair and lawful. This means that you have a right to understand how your data is collected and used and that there is a legal basis to the collection of your data. We also follow that data should only be collected if it is necessary for either you or our platform. Even when collecting your data is necessary for our platform, we will not collect it, if it infringes your interest in privacy.</p>
    <p className='pb-8'>Since we collect and process personal data, we have an obligation to keep that data private and secure. We use all necessary legal mechanisms, up-to-date technology and best practices of data protection to ensure that your data is kept safe after its collection.</p>
    <p className='pb-8'>Since our company is based in Estonia, a member state of the European Union, we have taken all necessary steps to be compliant with the rules of the General Data Protection Regulation (GDPR).</p>

    <p className='pb-8'>3.2 What are the differences between a student and a tutor account?</p>
    <p className='pb-8'>There are two different types of an account which you can create to use Joyed: a student's account, and a tutor's account.</p>
    <p className='pb-8'>We emphasize that our Privacy Policy and the rules herein apply to all these accounts in the same manner. We keep in mind that our average user is a teenager, whose data needs more vigorous protection, and we have created this Privacy Policy on the basis of that knowledge. Even so, we treat a tutor's data with the same care. We do however collect different data from you, depending whether you use Joyed as a student or a tutor.</p>

    <p className='pb-8'>3.3 If you are registering as a student</p>
    <p className='pb-8'>We only collect the minimum amount of personal data needed. To create an account, we ask you for the following information:</p>
    <p className='pb-8'>
        - Your email address<br/>
        - Password<br/>
        - Player name
    </p>
    <p className='pb-8'>Your tutor can also fill this data for your account in their profile:</p>
    <p className='pb-8'>
        - Student's first name<br/>
        - Student's grade
    </p>
    <p className='pb-8'>Your player name and email address will be visible to your tutor, and your password will be hidden from other people.</p>
    <p className='pb-8'>The legal basis for processing all this data is our legitimate interest to process. It is in our interest to provide people access to our platform. Login data is necessary to create a permanent and secure account. An important part of Joyed, something that makes us different from other similar platforms, is that our users can see how their skills are compared to other players in their country or grade/age group. We also provide a service to tutors who can use Joyed as an educational tool.</p>
    <p className='pb-8'>We do not demand, request or prompt you to give us any other personal data during your use of Joyed. Our platform does not have any features where you are able to give out additional personal data yourself.</p>
    <p className='pb-8'>In addition to all the data mentioned above, our platform also collects some data automatically from all users (see below for automatically collected data).</p>

    <p className='pb-8'>3.5 If you are registering as a tutor</p>
    <p className='pb-8'>We ask you for the following personal data when you sign up for an account:</p>
    <p className='pb-8'>
        - Your full name<br/>
        - Email address<br/>
        - Password
    </p>
    <p className='pb-8'>You may also create an account by using your Google account. In that case Google sends us your email address, name and picture.</p>
    <p className='pb-8'>This data is needed to provide you with secure login information and for identification purposes. The legal basis for processing this data is your consent and our legitimate interest to provide a secure way to use the platform and communicate with the user if it is necessary for the user's interests.</p>
    <p className='pb-8'>In addition to all the data mentioned above, our platform also collects data automatically from any user (see below for automatically collected data).</p>

    <p className='pb-8'>3.6 What about the information about how I play the games?</p>
    <p className='pb-8'>We also collect data related to your performance in the games ('Game Data'). This Game Data includes your progress on our platform, how many games you have played, what mistakes you made when playing etc. This data is shown to you in an aggregated form as player level and goals progress for reaching certain achievements.</p>
    <p className='pb-8'>The purpose of collecting, saving and aggregating Game Data is to provide users an optimal experience - when you know your skill level, we can recommend and you can choose games that fit your skill level, that are not too easy or too difficult. It also helps us improve our educational algorithms. Saving and showing Game Data is helpful to incentivize students to progress in the game, which in turn means that they get better at math.</p>
    <p className='pb-8'>Some components of Game Data will be reported to a tutor account that is linked to a student account. A student account will be linked with a tutor's account when your tutor adds you to their list of students.</p>

    <p className='pb-8'>A linked tutor account can see your progress in games: how many games you have played, how many correct answers you got, what mistakes you made and so on. Although Joyed is not primarily intended to be a tool for assessing someone's skill, we have created these features so that a tutor can use Joyed as part of a curriculum. By accessing this Game Data, a tutor can see what the child/student already knows well and where individual teaching is needed for improvement.</p>

    <p className='pb-8'>4. What data does Joyed collect automatically?</p>
    <p className='pb-8'>Joyed may also collect your personal data automatically from your computer or mobile device when you use our platform. This automatically collected data includes the following:</p>
    <p className='pb-8'>
        - Your internet browser<br/>
        - Your IP-address and mobile network information<br/>
        - Device information such as operating system<br/>
        - Geolocation data (which may identify your country and city but not your street)<br/>
        - Information about sites you have visited before and after<br/>
        - Server log information, e.g login times and site navigation<br/>
        - Other information collected through cookies, web beacons, and pixel tags
    </p>
    <p className='pb-8'>Cookies are small bits of information stored on your device. You may use your internet browser's (mobile app's) settings to change whether you accept cookies and if so, which cookies. If you do not accept cookies, you may not be able to use Joyed or some of its features. Joyed uses cookies to temporarily store data. This includes such data as your email address, username, settings and preferences.</p>

    <p className='pb-8'>5. What third parties have access to my data and why?</p>
    <p className='pb-8'>We may share your personal data with our service providers who we have contracted to perform certain functions or services on our behalf, such as hosting the platform, creating and managing accounts, managing databases, performing analytics and site metrics, logging errors etc. The third-party service providers may also use cookies, web beacons, pixel tags and other technologies to collect data in the form of persistent identifiers such as your device number and online activity, for example the pages you viewed, links you clicked and time you spent on our page.</p>
    <p className='pb-8'>Joyed's team will review any third party's privacy policy before contracting them to ensure that they are also compliant with the same regulations we are and that they will keep your data safe and secure. We share data with and allow it to be used by third parties only when and to the extent it is necessary for the specific task that the third party was contracted to do.</p>
    <p className='pb-8'>Other than that, we will share your personal data only if there is a legal requirement or if there is a warranted exception in the applicable law.</p>

    <p className='pb-8'>5.1 Can other users or the general public access my personal data?</p>
    <p className='pb-8'>It is important to specify how we do not use your personal data:</p>
    <p className='pb-8'>
        - We do not give, sell or lease a student's personal data to advertisers or for the purpose of direct advertising.<br/>
        - We will not make your contact information public.<br/>
        - We do not share or give access to your data to third parties except for our service providers (see below) or unless legally obligated to do so.<br/>
        - Our platform does not have third-party advertisements.
    </p>
    <p className='pb-8'>We will not publish or give public access to your data like your username, login information or email address. We may share some Game Data with the general public outside of the Joyed platform if the data is aggregated and non-personally identifiable, for example statistics in marketing materials about where our users are mostly located or what games are most popular.</p>
    <p className='pb-8'>Your profile includes only the following:</p>
    <p className='pb-8'>
        - Your anonymous player nickname<br/>
        - Your survey answers<br/>
        - Your player level, meaning the number of Goals you've accomplished
    </p>
    <p className='pb-8'>Your performance data is only shown to a tutor's account if it is linked with your account.</p>

    <p className='pb-8'>6. How does Joyed keep my data secure?</p>
    <p className='pb-8'>We undertake to keep your personal data safe and secure, avoid unauthorized access, disclosure, misuse or alteration of your personal data.</p>
    <p className='pb-8'>We use all appropriate and reasonable security measures to do that, for example:</p>
    <p className='pb-8'>
        - We have an internal data security policy.<br/>
        - We use contemporary measures for digital security and follow industry standards.<br/>
        - We have relevant training for our employees.<br/>
        - Our employees are contractually obligated to keep confidentiality.<br/>
        - Access to personal data is only possible for appropriate employees.
    </p>
    <p className='pb-8'>We also use the following technical precautions:</p>
    <p className='pb-8'>Password protection. Creating a secure password is the key to protecting your account, because having a secure password prevents unauthorized access to your account. When storing the password, we never store the password as plain text. We always salt the password and encrypt it before storing it to the database. We recommend that you choose a password that is hard to guess and isn't too common, but is easy to remember. Additional information on password security, why having a secure password is important and how to choose a good password, can be found from The Cybersecurity and Infrastructure Security Agency (CISA) website.</p>
    <p className='pb-8'>Database protection. Our database servers use different servers from our application servers and are accessible only to specific IP addresses. Any IP address that is not listed in the IP address whitelist will not be able to access the database. All databases are also password protected.</p>
    <p className='pb-8'>SSL encryption. Any data that is transferred through our application is protected by encryption with the Secure Socket Layer (SSL) protocol. SSL protocol makes it difficult for unwanted parties to access your data when in transit.</p>
    <p className='pb-8'>We also periodically review our practices of collecting and processing data against software industry standards. We try to keep our practices up to date in the face of technological advancements that malicious people could use to access data.</p>
    <p className='pb-8'>Please keep in mind that transmitting and storing data digitally cannot be 100 percent secure. While we do our best, we cannot guarantee that nothing bad will ever happen. You are responsible for keeping your password safe. We do not accept liability for unintentional disclosure of personal data to the fullest extent permitted by law.</p>
    <p className='pb-8'>For users in the European Union / European Economic Area. Joyed is based in Estonia. Your personal data is stored on our servers located in Ireland. Some of our third-party service providers are located outside the EU/EEA, for example the United States. Therefore it is possible that your data is transferred to servers outside the EU/EEA. The data protection laws of other countries may not be as comprehensive as those in the EU/EEA.</p>
    <p className='pb-8'>We transfer personal data to a country outside the EU/EEA only if the country is recognized by the EU as having an adequate level of data security or if the transfer is made with your consent or is made in our legitimate interest. When we transfer your personal data, we will take reasonable steps to ensure that your rights continue to be protected, for example by concluding the EU Standard Contractual Clauses with third parties.</p>

    <p className='pb-8'>7. What are my rights related to my personal data that Joyed collects?</p>
    <p className='pb-8'>You have the following rights in relation to your personal data:</p>
    <p className='pb-8'>
        - The right to be informed about what personal data is processed and why.<br/>
        - The right to access the personal data that we hold.<br/>
        - The right to have your data corrected or amended.<br/>
        - The right to request Joyed to delete your personal data. This applies if your personal data is no longer required for the purpose it was collected for or if you revoke your consent.<br/>
        - The right to restrict us from processing your personal data for a certain period of time.<br/>
        - The right to object to further processing of your personal data if it is not consistent with the primary purpose for which your data was collected (e.g if we use it for direct marketing instead).
    </p>
    <p className='pb-8'>We may collect and process your data for purposes not named in this Privacy Policy or without your consent, only if there is a specific and clear exception in the applicable law (e.g COPPA or GDPR). This happens, for example, in cases where we need to comply with a legal obligation, when it is necessary to protect the safety of a child, take precautions against liability, protect the security of our platform etc.</p>
    <p className='pb-8'>We will store your personal data until Joyed exists, even if you stop using your account for a while. We will usually delete all stored data if your account is deleted due to your breach of contract or your request. This is however not the case if you have been a subscriber of premium content and have entered into a monetary transaction with us. In that case we will keep storing the data until we have a legal obligation to do so (for example an obligation to preserve accounting documents for seven years) or until it can be used to defend our legitimate interests (for example to use as evidence in case of a legal dispute between us).</p>
    <p className='pb-8'>As laws change and our platform evolves, so does the Privacy Policy need amending from time to time. Minor changes will be reflected on this page, please see above for the time of last update. We will notify users about larger material changes to the Privacy Policy directly by email or through a user's account. If you do not agree with the updates, you may terminate your account before the updates take force. Continuing to use Joyed after our Privacy Policy has been changed means that you agree with those changes.</p>

    <p className='pb-8'>The controller and processor of your personal data is Joyed OÜ (Estonian Commercial Registry code 16989993). If you want further information about how we process your personal data or wish to exercise any of your rights above you may contact the Joyed at the address below.</p>
    <p className='pb-8'>If you wish to make a formal complaint about the way Joyed has processed your personal data, you may contact the data protection authorities of the country where you are resident at. Alternatively you can contact the Estonian Data Protection Inspectorate at 39 Tatari St., 10134 Tallinn, Estonia or through email at info@aki.ee.</p>

    <p className='pb-8'>8. Account deletion</p>
    <p className='pb-8'>If you wish to delete your account and all your data Joyed has, please contact us at hanna@joyed.ai.</p>

    <p className='pb-8'>Contact information</p>
    <p className='pb-8'>
        For all questions, comments and requests, please contact us at:<br/>
        Joyed OÜ<br/>
        Saue tn 9/2-9<br/>
        10612 Tallinn, Estonia<br/>
        Phone: +372 53986299<br/>
        hanna@joyed.ai
    </p>
</div>
    )
}

export default PrivacyPolicy
