// Materials.styles.js
const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: 'auto',
        textAlign: 'center',
    },
    heading: {
        color: '#333',
        marginBottom: '20px',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
    },
    item: {
        backgroundColor: '#f0f0f0',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        textAlign: 'left',
    },
    link: {
        color: '#5667fd',
        textDecoration: 'none',
        fontSize: '16px',
    },
}

export default styles
