// src/store/store.jsx

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { authReducer } from '../features/auth/authSlice' // Ensure the path to your reducers is correct

const rootReducer = combineReducers({
    auth: authReducer,
    // include other reducers here as needed
})

const persistConfig = {
    key: 'root', // The key for the persist
    storage, // The storage to use
    whitelist: ['auth'], // Which reducer want to store, you can add more if needed
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST'],
            },
        }),
})

export const persistor = persistStore(store)
