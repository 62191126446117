import React, { useState } from 'react'
/* import { useNavigate } from 'react-router-dom' */
/* import styles from './HeroSection.styles.js' */
import { Modal } from '../../../../components/index.js';
import { TutorRegister } from '../../../index.js';
import TutorLoginForm from '../../../LoginPage/TutorLoginForm/TutorLoginForm.jsx';
/* import hero from '../../../../assets/images/hero-800.png' */
import { useNavigate } from 'react-router-dom'

function HeroSection() {
    /* const navigate = useNavigate() */
    const [open, setOpen] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState('')
    const [loginType, setLoginType] = useState('tutor')

    // Function to navigate to the access code entry page
   /*  const handleEnterAccessCode = () => {
        navigate('/student-signup')
    } */

  
    const handleRegister = () => {
        setOpen(true) 
        /* navigate('/tutor-register') */
        /* navigate('/account-creation') */
    }

    const handleLogin = () => {
        setOpenLogin(true)
        /* navigate('/login') */
    }

    const handleLoginSuccess = (userType, userData) => {
        navigate('/dashboard')
    }

    const handleLoginError = (error) => {
        setErrorMessage(error)
        setLoginType('')
    }
console.log(loginType)
console.log(errorMessage)
    return (
        <div className='md:mt-[120px] mt-14 max-w-[750px]' >
                <h1 className='font-lilita font-normal md:text-6xl text-3xl mb-12'>Practice tool<br /><span className='text-main-color'>that tutors and students love</span></h1>
                <p className='md:text-[22px] text-lg leading-[30px] font-normal'>Our tutor-led learning experiences give students the chance to practice, play, and grow on their own terms, at their own pace.
</p>
                <div className='flex mt-9'>
                    <button className='font-lilita md:text-4xl text-xl font-normal bg-main-color sm:py-3 py-2 sm:px-6 px-3 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)]  sm:mr-9 mr-2 [text-shadow:0px_2px_2px_#00000040] '
                        onClick={handleRegister}
                    >
                        Free for tutors
                    </button>
                    <button className='md:text-[22px] text-lg p-1 font-normal'  onClick={handleLogin}>
                        Tutor login
                    </button>
                </div>
                <Modal open={open} noOvals={false} onClose={() => setOpen(false)}>
                    <TutorRegister/>
                </Modal>
                <Modal open={openLogin} noOvals={false} onClose={() => setOpenLogin(false)}>
                    <TutorLoginForm onLoginSuccess={(userData) =>
                        handleLoginSuccess('tutor', userData)
                    }
                    onLoginError={handleLoginError} />
                </Modal>
        </div>
    )
}

export default HeroSection
