import React, { useEffect, useState } from 'react'
import styles from './Preferences.styles.js' // Assume similar styling to GoalDefinitionForm
import config from '../../../../../config'
import { useSelector } from 'react-redux'

function Preferences() {
    const apiBaseUrl = config.API_BASE_URL
    const studentId = useSelector((state) => state.auth.userInfo.id)
    const [loading, setLoading] = useState(false)

    const [preferences, setPreferences] = useState({
        studyTime: 'Evenings',
        learningMaterials: [],
        preferredTutors: 'Any',
        studentId: studentId,
    })

    // Fetch preferences on component mount
    useEffect(() => {
        const fetchPreferences = async () => {
            setLoading(true)
            try {
                const response = await fetch(
                    `${apiBaseUrl}/student-preferences/${studentId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                if (!response.ok) {
                    throw new Error('Failed to fetch preferences')
                }
                const data = await response.json()
                setPreferences(data) // Assuming the API returns the whole preferences object
            } catch (error) {
                console.error('Error fetching preferences:', error)
                alert('Failed to fetch preferences')
            }
            setLoading(false)
        }

        fetchPreferences()
    }, [apiBaseUrl, studentId])

    // Handle input change for text and select inputs
    const handleChange = (e) => {
        const { name, value } = e.target
        setPreferences((prevState) => ({ ...prevState, [name]: value }))
    }

    // Handle change for checkboxes
    const handleCheckboxChange = (e) => {
        const { name, checked, value } = e.target
        if (checked) {
            // Add to the array
            setPreferences((prevState) => ({
                ...prevState,
                [name]: [...prevState[name], value],
            }))
        } else {
            // Remove from the array
            setPreferences((prevState) => ({
                ...prevState,
                [name]: prevState[name].filter((item) => item !== value),
            }))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('Preferences submitted:', preferences)
        setLoading(true)
        // Send preferences to the server /api/v1/student-preferences/:studentId
        try {
            const response = await fetch(
                `${apiBaseUrl}/student-preferences/${studentId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(preferences),
                },
            )
            if (!response.ok) {
                throw new Error('Failed to save preferences')
            }
            alert('Preferences saved successfully!')
        } catch (error) {
            console.error('Failed to save preferences:', error)
            alert('Failed to save preferences')
        }
        setLoading(false)
    }

    const learningOptions = [
        'Video Lectures 📹',
        'Interactive Quizzes 🧩',
        'Textbooks 📚',
        'Study Groups 👥',
        'One-on-One Tutoring 👨‍🏫',
        'Flashcards 🗂️',
        'Peer Tutoring 👫',
        'Self-paced Online Courses ⏱️',
    ]

    return (
        <div style={styles.container}>
            <form onSubmit={handleSubmit} style={styles.form}>
                <h2 style={styles.heading}>Set Your Learning Preferences</h2>

                <label style={styles.label}>
                    Preferred Study Time:
                    <select
                        name="studyTime"
                        value={preferences.studyTime}
                        onChange={handleChange}
                        style={styles.input}
                    >
                        <option value="Mornings">Mornings</option>
                        <option value="Afternoons">Afternoons</option>
                        <option value="Evenings">Evenings</option>
                    </select>
                </label>

                <h3 style={styles.label}>Preferred Learning Materials:</h3>
                {learningOptions.map((option) => (
                    <div key={option} style={styles.formGroup}>
                        <label style={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                name="learningMaterials"
                                value={option}
                                onChange={handleCheckboxChange}
                                checked={preferences.learningMaterials.includes(
                                    option,
                                )}
                            />
                            {option}
                        </label>
                    </div>
                ))}

                <label style={styles.label}>
                    Preferred Tutors:
                    <select
                        name="preferredTutors"
                        value={preferences.preferredTutors}
                        onChange={handleChange}
                        style={styles.input}
                    >
                        <option value="Any">Any</option>
                        <option value="Experts">Experts</option>
                        <option value="Peers">Peers</option>
                    </select>
                </label>

                <button type="submit" style={styles.button} disabled={loading}>
                    Save Preferences
                </button>
            </form>
        </div>
    )
}

export default Preferences
