import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styles from './AccountCreation.styles'
import { login } from '../../../features/auth/authActions'
import config, { apiBaseUrl } from '../../../config'

function AccountCreation() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const apiBaseUrl = config.API_BASE_URL

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords don't match.")
            return
        }

        setLoading(true)
        try {
            const response = await fetch(
                `${apiBaseUrl}/student/register`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: formData.name,
                        email: formData.email,
                        password: formData.password,
                    }),
                },
            )

            const data = await response.json()
            if (!response.ok) {
                throw new Error(data.message || 'Failed to create account.')
            }

            dispatch(
                login({
                    role: 'student',
                    email: formData.email,
                    name: formData.name,
                }),
            )
            navigate('/dashboard')
        } catch (apiError) {
            setError(
                apiError.message ||
                    'Failed to create account. Please try again.',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={styles.container}>
            <form onSubmit={handleSubmit} style={styles.form}>
                <h2>Create Your Account</h2>
                {error && <p style={styles.error}>{error}</p>}
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Your Name"
                    required
                    style={styles.input}
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Your Email"
                    required
                    style={styles.input}
                />
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Create a Password"
                    required
                    style={styles.input}
                />
                <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm Password"
                    required
                    style={styles.input}
                />
                <button type="submit" disabled={loading} style={styles.button}>
                    {loading ? 'Creating Account...' : 'Create Account'}
                </button>
            </form>
        </div>
    )
}

export default AccountCreation
