import React from 'react'
import { NavLink } from 'react-router-dom'
/* import styles from './Sidebar.styles' */
import dashboardIconActive from '../../../../assets/images/dashboardIconActive.svg'
import studentIconActive from '../../../../assets/images/studentIconActive.svg'
import homeworkIconActive from '../../../../assets/images/homeworkIconActive.svg'
import dashboardIcon from '../../../../assets/images/dashboardIcon.svg'
import studentIcon from '../../../../assets/images/studentIcon.svg'
import homeworkIcon from '../../../../assets/images/homeworkIcon.svg'
import activeMark from '../../../../assets/images/activeMark.svg'
import { Link } from 'react-router-dom'
import JoyedLogo from '../../../../assets/images/logo-landing.png'

function Sidebar({ links, onboardingHandler }) {

    return (
        <aside className='w-fit lg:min-w-[300px] -mt-24 bg-gradient-to-br from-[#201D47] to-[#17153A] lg:min-h-fit h-auto md:flex hidden'>

            <div className='w-full relative pt-24 bg-gradient-to-br from-[rgba(38, 34, 80, 0.65)] via-[rgba(27, 25, 66, 0.85)] to-[#17163B] h-screen'>
                <Link to="/" className='absolute top-[27px] lg:left-[58px] left-2' >
                    <img className='' src={JoyedLogo} alt="Joyed logo" />
                </Link>
                <nav>
                    <ul className='list-none mt-4 '>
                        <li className='mb-[10px] py-2 '>
                            <NavLink to="/dashboard" end>
                                {({ isActive }) => {
                                    return isActive ? (
                                        <div className='flex items-center relative'>
                                            <img className='absolute left-0]' src={activeMark} alt="active" />
                                            <img className='lg:mr-[18px] mr-8 ml-6 lg:ml-[46px]' src={dashboardIconActive} alt="dashboard active" />
                                            <nav className="text-center text-[#53B9EA] lg:flex hidden font-bold tracking-wider">Dashboard</nav>
                                        </div>
                                    ) : (
                                        <div className='flex items-center'>
                                            <img className='lg:mr-[18px] mr-8 ml-6 lg:ml-[46px]' src={dashboardIcon} alt="dashboard active" />
                                            <nav className="text-center  font-normal text-[#6F6C99] lg:flex hidden tracking-wider">Dashboard</nav>
                                        </div>
                                    );
                                }}
                            </NavLink>
                        </li>
                        {links.map((link) => (
                            <li key={link.path} className='mb-[10px] py-2'>
                                <NavLink to={`/dashboard/${link.path}`}>
                                    {({ isActive }) => {
                                        return isActive ? (
                                            <div className='flex items-center relative'>
                                                <img className='absolute left-0' src={activeMark} alt="active" />
                                                <img className='lg:mr-[18px] mr-8 ml-6 lg:ml-[46px]' src={link.name === 'Students' ? studentIconActive : homeworkIconActive} alt={link.name + 'active'} />
                                                <nav className="tracking-wider text-center text-[#53B9EA] lg:flex hidden font-bold">{link.name}</nav>
                                            </div>
                                        ) : (
                                            <div className='flex items-center'>
                                                <img className='lg:mr-[18px] mr-8 ml-6 lg:ml-[46px]' src={link.name === 'Students' ? studentIcon : homeworkIcon} alt={link.name + 'not active'} />
                                                <nav className="tracking-wider text-center font-normal text-[#6F6C99] lg:flex hidden ">{link.name}</nav>
                                            </div>
                                        );
                                    }}
                                </NavLink>
                                {/*  <NavLink
                                    to={`/dashboard/${link.path}`}
                                >
                                    {link.name}
                                </NavLink> */}
                            </li>
                        ))}
                        {/* <li className='mb-[10px]'>
                            <button
                                onClick={onboardingHandler}
                                style={styles.link} // Use the same style for consistency
                            >
                                Start Onboarding
                            </button>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default Sidebar
