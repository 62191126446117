const colors = {
    primary: '#5667fd',
    secondary: '#5d6169',
    success: '#28a745',
    tertiary: '#2e5caf',
    quaternary: '#658cc2',
    background: '#F8F9FA',
    text: '#212529',
    border: '#DEE2E6',
}

export default colors
