import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styles from './TutorRegister.styles'
import { login } from '../../features/auth/authActions'
import showPassword from '../../assets/images/showPassword.svg'
import config from '../../config'

function AccountCreation() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [passwordVisible, setPasswordVisible] = useState()
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false)
    const apiBaseUrl = config.API_BASE_URL

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords don't match.")
            return
        }

        setLoading(true)
        try {
            const response = await fetch(
                `${apiBaseUrl}/tutor/register`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: formData.name,
                        email: formData.email,
                        password: formData.password,
                    }),
                },
            )

            if (!response.ok) {
                const errorResponse = await response.json()
                throw new Error(errorResponse.message || 'Failed to create account.')
            }

            const responseData = await response.json()
            console.log('Response data:', responseData)

            if (!responseData.tutor) {
                throw new Error('Unexpected response format.')
            }

            const tutor = responseData.tutor
            console.log(`Tutor data as json string: ${JSON.stringify(tutor)}`)
            dispatch(
                login({
                    role: tutor.role,
                    email: tutor.email,
                    name: tutor.name,
                    id: tutor._id,
                }),
            )
            navigate('/dashboard')
        } catch (apiError) {
            setError(apiError.message || 'Failed to create account. Please try again.')
        } finally {
            setLoading(false)
        }
    }


    return (
        <div className='text-white'>
            <form onSubmit={handleSubmit} style={styles.form}>
                {/* <h2>Register a Tutor Account v=0.134</h2> */}
                <h2 className='font-lilita sm:text-[50px] sm:mb-[54px] mb-[30px] text-4xl leading-[30px] font-normal'>Create account</h2>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Your Name"
                    required
                    className='bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 placeholder:text-white font-light mb-1'
                    />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Your Email"
                    required
                    className='bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 placeholder:text-white font-light mb-1'
                    />
                <div className='relative'>
                <input
                    type={passwordVisible ? 'text': 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Create a Password"
                    required
                    className='bg-[#263768] w-full rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 placeholder:text-[#5C76B4] font-light mb-1 '
                    />
                    <div className={`absolute top-4 p-1 right-4 ${passwordVisible ? 'opacity-40' : '' }`} onClick={() => setPasswordVisible(!passwordVisible)}>
                        <img src={showPassword} alt="show password" />
                    </div>
                </div>
                <div className='relative'>
                    <input
                        type={passwordConfirmVisible ? 'text' : 'password'}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        placeholder="Confirm Password"
                        required
                        className='bg-[#263768] w-full rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 placeholder:text-[#5C76B4] font-light mb-1'
                        />
                    <div className={`absolute top-4 p-1 right-4 ${passwordConfirmVisible ? 'opacity-40' : '' }`} onClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}>
                        <img src={showPassword} alt="show password" />
                    </div>
                </div>
                {error && <p className='pl-4 mt-2 text-[#F26325] text-sm'>{error}</p>}
                <button type="submit" disabled={loading} className='font-lilita md:text-4xl text-xl font-normal bg-main-color py-3 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] mt-2 [text-shadow:0px_2px_2px_#00000040] w-full'>
                    {loading ? 'Creating Account...' : 'Sign up'}
                </button>
                <div className='sm:mt-4 mt-2 text-lg w-full text-center text-[#5C76B4]'>Already have an account? <Link className='font-semibold text-[#5667FD] inline-block' to={'/login'}>Log in</Link></div>
            </form>
        </div>
    )
}

export default AccountCreation
