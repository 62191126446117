import React from 'react'
import Sidebar from '../common/Sidebar/Sidebar'
/* import styles from '../Dashboard.styles' */

import Homework from './components/TutorHomework/TutorHomework'
import { Route, Routes } from 'react-router-dom'
import TutorDashboardView from './components/TutorDashboardView/TutorDashboardView'
import StudentManagement from './components/StudentManagement/StudentManagement'
/* import SessionPlanner from './components/SessionPlanner/SessionPlanner' */

function TutorDashboard() {
    /* const [currentView, setCurrentView] = useState('students') // eslint-disable-line no-unused-vars */
    const links = [
        { path: 'students', name: 'Students' },
        { path: 'homework', name: 'Homework' },
        /* { path: 'sessionPlanner', name: 'Session Planner' }, */
    ]
    
    return (
        <div className="flex w-full min-h-screen absolute pt-24  top-0 left-0 text-white">
            <Sidebar links={links} />
            <main className="-mt-24 bg-gradient-to-br from-[#201D47] to-[#17153A] w-full" >
                <Routes>
                    <Route path="/" element={<TutorDashboardView />} />
                    <Route path="students" element={<StudentManagement />} />
                    <Route path="homework" element={<Homework />} />
                    {/* <Route path="sessionPlanner" element={<SessionPlanner />} /> */}
                </Routes>
            </main>
        </div>
    )
}

export default TutorDashboard
