import React from 'react'
import { Chrono } from 'react-chrono'
import goalsData from './templates/StudyPlan1.json' // Adjust the import path as needed
import colors from '../../../../../../../styles/colors' // Adjust the import path as needed

const GoalsRoadmap = () => {
    const items = goalsData['Weekly Breakdown'].map((weekInfo) => ({
        title: weekInfo.Date,
        cardTitle: `Week ${weekInfo.Week}`,
        cardSubtitle: weekInfo.Meeting,
        cardDetailedText: weekInfo.Task,
    }))

    return (
        <div style={{ height: '500px' }}>
            <h2>{goalsData['Plan Name']}</h2>
            <p>Duration: {goalsData['Duration']}</p>
            <Chrono
                items={items}
                theme={{
                    primary: colors.primary,
                    secondary: colors.background,
                    cardBgColor: '#f9f9f9',
                    titleColor: colors.text,
                }}
                mode="VERTICAL_ALTERNATING"
                slideShow
                slideItemDuration={4500}
            />
        </div>
    )
}

export default GoalsRoadmap
