const taskListData = {
    'Study Plan': '8th Grade Math - Trigonometry Focus',
    Duration: '2 Months',
    'Weekly Tasks': [
        {
            Week: 1,
            Tasks: [
                {
                    Description:
                        'Watch a video introduction to angles and their importance.',
                    Duration: '30 minutes',
                    Due: 'April 10, 2024, 5:30 PM',
                },
                {
                    Description:
                        'Complete a worksheet on identifying angles in daily objects.',
                    Duration: '45 minutes',
                    Due: 'April 12, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 2,
            Tasks: [
                {
                    Description:
                        'Interactive lesson on sine, cosine, and tangent ratios.',
                    Duration: '1 hour',
                    Due: 'April 16, 2024, 5:00 PM',
                },
                {
                    Description:
                        'TutorHomework.jsx: Calculate sides of right triangles using trig ratios.',
                    Duration: '1 hour',
                    Due: 'April 18, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 3,
            Tasks: [
                {
                    Description:
                        'Build a paper model of the unit circle with marked angles.',
                    Duration: '1.5 hours',
                    Due: 'April 22, 2024, 7:30 PM',
                },
                {
                    Description:
                        'Quiz on unit circle and basic trigonometry concepts.',
                    Duration: '30 minutes',
                    Due: 'April 28, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 4,
            Tasks: [
                {
                    Description:
                        'Group activity: Create wave motions using strings to demonstrate sine and cosine functions.',
                    Duration: '2 hours',
                    Due: 'May 2, 2024, 6:00 PM',
                },
                {
                    Description:
                        'Individual reflection on wave motion activity and its relation to trig functions.',
                    Duration: '30 minutes',
                    Due: 'May 4, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 5,
            Tasks: [
                {
                    Description:
                        'Research and outline your mini-project on real-life applications of trigonometry.',
                    Duration: '2 hours',
                    Due: 'May 8, 2024, 5:30 PM',
                },
                {
                    Description:
                        'Progress report: Present your research findings and project outline to a peer for feedback.',
                    Duration: '30 minutes',
                    Due: 'May 12, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 6,
            Tasks: [
                {
                    Description:
                        'Develop a PowerPoint presentation detailing your mini-project, including trigonometric concepts used.',
                    Duration: '3 hours',
                    Due: 'May 14, 2024, 6:00 PM',
                },
                {
                    Description:
                        'Peer review session: Exchange presentations for feedback on clarity and content.',
                    Duration: '1 hour',
                    Due: 'May 17, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 7,
            Tasks: [
                {
                    Description:
                        'Incorporate feedback and finalize your presentation.',
                    Duration: '2 hours',
                    Due: 'May 20, 2024, 7:00 PM',
                },
                {
                    Description:
                        'Rehearse your presentation and time it to ensure it fits within the allotted timeframe.',
                    Duration: '1 hour',
                    Due: 'May 26, 2024, 5:00 PM',
                },
            ],
        },
        {
            Week: 8,
            Tasks: [
                {
                    Description:
                        'Present your trigonometry mini-project to the class.',
                    Duration: '10 minutes per presentation',
                    Due: 'May 30, 2024, 5:00 PM - 6:30 PM',
                },
                {
                    Description:
                        'Write a reflective essay on your learning journey through this trigonometry study plan.',
                    Duration: '1 hour',
                    Due: 'June 1, 2024, 5:00 PM',
                },
            ],
        },
    ],
}

export default taskListData
