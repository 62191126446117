// Tutors.jsx
import React, { useState } from 'react'
import CurrentTutors from './components/CurrentTutors/CurrentTutors'
import AddTutor from './components/AddTutor/AddTutor'
import PastTutors from './components/PastTutors/PastTutors'
import styles from './Tutors.styles' // Adjust the import path as needed

function Tutors() {
    const [view, setView] = useState('current') // Possible values: 'current', 'past', 'add'

    // Function to determine if a button is active
    const isActive = (buttonView) => view === buttonView

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Tutor Management</h1>
            <div style={styles.nav}>
                <button
                    onClick={() => setView('current')}
                    style={{
                        ...styles.button,
                        ...(isActive('current') ? styles.buttonActive : {}),
                    }}
                >
                    Current Tutors
                </button>
                <button
                    onClick={() => setView('past')}
                    style={{
                        ...styles.button,
                        ...(isActive('past') ? styles.buttonActive : {}),
                    }}
                >
                    Past Tutors
                </button>
                <button
                    onClick={() => setView('add')}
                    style={{
                        ...styles.button,
                        ...(isActive('add') ? styles.buttonActive : {}),
                    }}
                >
                    Add Tutor
                </button>
            </div>

            {view === 'current' && <CurrentTutors />}
            {view === 'past' && <PastTutors />}
            {view === 'add' && <AddTutor />}
        </div>
    )
}

export default Tutors
