import React, { useState, useEffect } from 'react'
import config from '../../../../../../../config'
import { useSelector } from 'react-redux'

function CurrentTutors() {
    const apiBaseUrl = config.API_BASE_URL
    const [currentTutorsFullData, setCurrentTutorsFullData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const studentId = useSelector((state) => state.auth.userInfo.id) // get student ID from redux

    useEffect(() => {
        async function fetchTutorData(tutorId) {
            try {
                const response = await fetch(`${apiBaseUrl}/tutor/${tutorId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                if (!response.ok) {
                    throw new Error('Failed to fetch tutor data')
                }
                const data = await response.json()
                return data.tutor // Ensure the API responds with this structure or adjust accordingly
            } catch (error) {
                console.error('Failed to get tutor data:', error)
                return null // Handle failure silently or update state with error info
            }
        }

        async function fetchCurrentTutors() {
            setIsLoading(true)
            setError(null)
            try {
                const response = await fetch(
                    `${apiBaseUrl}/student/tutors/${studentId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )

                if (!response.ok) {
                    throw new Error('Failed to fetch tutors list')
                }
                const data = await response.json()
                const tutorPromises = data.tutors.map((tutorId) =>
                    fetchTutorData(tutorId),
                ) // Array of promises
                const tutorsData = await Promise.all(tutorPromises) // Resolve all promises
                setCurrentTutorsFullData(
                    tutorsData.filter((tutor) => tutor !== null),
                ) // Filter out null responses if any
            } catch (error) {
                setError(error.message)
            } finally {
                setIsLoading(false)
            }
        }

        fetchCurrentTutors()
    }, [studentId, apiBaseUrl])

    return (
        <div>
            <h2>Current Tutors</h2>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : currentTutorsFullData.length > 0 ? (
                currentTutorsFullData.map((tutor, index) => (
                    <div key={index}>
                        <h3>
                            Tutor: {tutor.name} ({tutor.email})
                        </h3>{' '}
                        <p>Sessions so far: placeholder</p>
                        {/* Placeholder for actual data */}
                        <p>Next session: placeholder</p>
                        {/* Placeholder for actual data */}
                    </div>
                ))
            ) : (
                <p>No current tutors.</p>
            )}
        </div>
    )
}

export default CurrentTutors
