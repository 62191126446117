// Landing.styles.js
const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
    quickLinksContainer: {
        marginTop: '30px',
    },
    quickLinksList: {
        listStyleType: 'none',
        padding: 0,
    },
    // Add more styles as needed
}

export default styles
