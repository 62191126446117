import React from 'react'
import closeIcon from '../../assets/images/ic_close.svg'
import bgL from '../../assets/images/modal-bg-l.svg'
import bgR from '../../assets/images/modal-bg-r.svg'


function Modal({ open, onClose, children}) {
   return (
    <div className={`fixed inset-0 z-40 flex justify-center items-center transition-colors h-screen ${open ? 'visible bg-black/50 backdrop-blur-sm ' : 'invisible'}`} onClick={onClose}>
        <div className={`transition-all max-w-[400px] mx-10 sm:max-w-[502px] rounded-[20px] bg-[#190E49] ${open ? 'scale-100 opacity-100' : 'opacity-0'}`} onClick={((e) => e.stopPropagation())}>
            <img className='max-w-[207px] absolute top-0 right-0' src={bgR} alt="background" />
            <img className='max-w-[207px] absolute top-0' src={bgL} alt="background" />
            <button className='absolute top-4 right-4 py-1 px-2 rounded-full w-[36px] h-[36px] pointer-events-auto bg-[#5667FD] bg-opacity-25 z-20' onClick={onClose}><img width={24} height={24} src={closeIcon} alt="close"/></button>
            <div className='z-10 relative sm:px-14 sm:pb-8 sm:pt-[65px] pb-6 pt-[45px] px-8'>
                {children}
            </div>
        </div>
    </div>
   )
}

export default Modal
