import { useState } from 'react'

const Modal = ({ children, isVisible, closeModal }) => {
    const modalStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        width: '60%',
        zIndex: '1000',
        display: isVisible ? 'block' : 'none', // Control display with visibility
    }

    return <div style={modalStyle}>{children}</div>
}

// Slide 1 Component
const Slide1 = ({ onSelectGrade, onSelectLove }) => {
    const slideStyle = {
        marginBottom: '20px',
    }
    const buttonStyle = {
        marginRight: '10px',
    }

    return (
        <div style={slideStyle}>
            <h2>Study Goals:</h2>
            <p>Why are you studying Maths?</p>
            <button style={buttonStyle} onClick={() => onSelectGrade('E')}>
                I don’t want to fail aka E is enough
            </button>
            <button style={buttonStyle} onClick={() => onSelectGrade('ABCD')}>
                I want my grade to be A B C D
            </button>
            <button style={buttonStyle} onClick={onSelectLove}>
                I just :heart_eyes: Maths and want to get even better
            </button>
        </div>
    )
}

// Slide 2 Component
const Slide2 = ({ onSelectConfidence }) => {
    const slideStyle = {
        marginBottom: '20px',
    }
    const buttonStyle = {
        marginRight: '10px',
    }

    return (
        <div style={slideStyle}>
            <h2>Math Confidence</h2>
            <p>How do you rate your Maths confidence right now?</p>
            <button
                style={buttonStyle}
                onClick={() => onSelectConfidence('pensive')}
            >
                😔
            </button>
            <button
                style={buttonStyle}
                onClick={() => onSelectConfidence('neutral')}
            >
                😐
            </button>
            <button
                style={buttonStyle}
                onClick={() => onSelectConfidence('relaxed')}
            >
                ☺️
            </button>
        </div>
    )
}

// Slide 3 Component
const Slide3 = ({ onSelectPriority }) => {
    const slideStyle = {
        marginBottom: '20px',
    }

    return (
        <div style={slideStyle}>
            <h2>We’ve got you :muscle:</h2>
            <p>What is most important for you right now?</p>
            <button onClick={() => onSelectPriority('stress')}>
                Feel less stressed out
            </button>
            <button onClick={() => onSelectPriority('control')}>
                Feel in control of my days and study time
            </button>
            <button onClick={() => onSelectPriority('focus')}>
                Be more focused and productive
            </button>
            <button onClick={() => onSelectPriority('track')}>
                Get back on track quickly
            </button>
        </div>
    )
}

// Slide 4 Component
const Slide4 = ({ onSelectMotivation }) => {
    const slideStyle = {
        marginBottom: '20px',
    }

    return (
        <div style={slideStyle}>
            <h2>One last thing …</h2>
            <p>What motivates you the best?</p>
            <button onClick={() => onSelectMotivation('goals')}>
                Setting my own goals
            </button>
            <button onClick={() => onSelectMotivation('influencers')}>
                Influencers
            </button>
            <button onClick={() => onSelectMotivation('fun')}>
                Game and fun
            </button>
            <button onClick={() => onSelectMotivation('support')}>
                Support
            </button>
        </div>
    )
}

function OnBoardingView({ closeModal }) {
    const [currentSlide, setCurrentSlide] = useState(1)

    const handleNextSlide = () => {
        if (currentSlide >= 4) {
            closeModal() // Close the modal on the last slide
        } else {
            setCurrentSlide(currentSlide + 1)
        }
    }

    const handleSelectOption = (option) => {
        console.log(option)
        handleNextSlide()
    }

    return (
        <Modal isVisible={true} closeModal={closeModal}>
            {currentSlide === 1 && (
                <Slide1
                    onSelectGrade={handleSelectOption}
                    onSelectLove={handleSelectOption}
                />
            )}
            {currentSlide === 2 && (
                <Slide2 onSelectConfidence={handleSelectOption} />
            )}
            {currentSlide === 3 && (
                <Slide3 onSelectPriority={handleSelectOption} />
            )}
            {currentSlide === 4 && (
                <Slide4 onSelectMotivation={handleSelectOption} />
            )}
        </Modal>
    )
}

export default OnBoardingView
