import React from 'react'

function AboutPage() {
    return (
        <div className='text-white md:mt-[120px] sm:mt-[70px] mt-[40px] max-w-[740px] md:text-[22px] sm:text-lg text-base mb-16'>
            <h1 className='font-lilita md:text-6xl sm:text-5xl text-3xl mb-12'>Helping students develop better study goals and motivation</h1>
            <p className=' pb-8'>Starting in middle school, students often find school more difficult because they have lost intrinsic motivation, feel overwhelmed, and have gaps in their earlier learning.</p>
            <p className='pb-8'>The Joyed team is dedicated to helping students with gamified tools and personalized goals that fit learners' motivation and personality.</p>
            <p className='pb-8'>Tutors are empowered and supported by our AI-driven, easy-to-use assignment tool, which offers assistance with task creation, study logs, and student feedback for a more efficient and enjoyable learning process.</p>
        </div>
    )
}

export default AboutPage
