import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import styles from '../Dashboard.styles'
import Sidebar from '../common/Sidebar/Sidebar'
import Homework from './components/Homework/Homework'
import Tutors from './components/Tutors/Tutors'
import Goals from './components/Goals/Goals'
import Landing from './components/Landing/Landing'
import Preferences from './components/Preferences/Preferences'
import Materials from './components/Materials/Materials'
import TaskCalendarView from './components/Calendar/TaskCalendarView'
import FirstOnboarding from './components/FirstOnboarding/FirstOnboarding'

function StudentDashboard() {
    const [isOnboardingVisible, setOnboardingVisible] = useState(false)

    const links = [
        { path: 'homework', name: 'Homework' },
        { path: 'calendar', name: 'Calendar' },
        { path: 'tutors', name: 'Tutors' },
        { path: 'goals', name: 'Goals' },
        { path: 'materials', name: 'Materials' },
        { path: 'preferences', name: 'Preferences' },
    ]

    const handleOnboardingToggle = () => {
        setOnboardingVisible(!isOnboardingVisible)
    }

    return (
        <div className="dashboard-container" style={styles.dashboardContainer}>
            <Sidebar links={links} onboardingHandler={handleOnboardingToggle} />
            <main className="dashboard-content" style={styles.dashboardContent}>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="homework" element={<Homework />} />
                    <Route path="tutors" element={<Tutors />} />
                    <Route path="goals" element={<Goals />} />
                    <Route path="materials" element={<Materials />} />
                    <Route path="preferences" element={<Preferences />} />
                    <Route path="calendar" element={<TaskCalendarView />} />
                </Routes>
                {isOnboardingVisible && (
                    <FirstOnboarding
                        closeModal={() => setOnboardingVisible(false)}
                    />
                )}
            </main>
        </div>
    )
}

export default StudentDashboard
