import colors from '../../../../../styles/colors'

const styles = {
    button: {
        padding: '10px 20px',
        backgroundColor: colors.primary,
        color: colors.background,
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '20px',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    navButton: {
        padding: '10px',
        marginRight: '5px',
        backgroundColor: colors.primary,
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
}

export default styles
