// StudentSignUpPage.js
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './StudentSignUpPage.styles.js' // Import styles

function StudentSignUpPage() {
    const [accessCode, setAccessCode] = useState('')
    const navigate = useNavigate()

    const handleAccessCodeChange = (e) => {
        setAccessCode(e.target.value)
    }

    const handleProceed = () => {
        navigate('/account-creation', { state: { accessCode } })
    }

    const handleProceedWithoutCode = () => {
        navigate('/account-creation')
    }

    return (
        <div style={styles.container}>
            <input
                type="text"
                value={accessCode || '8d7f9s8d'}
                onChange={handleAccessCodeChange}
                placeholder="Enter Access Code If You Have One"
                style={styles.input}
            />
            <button onClick={handleProceed} style={styles.button}>
                Proceed with Code
            </button>
            <button
                onClick={handleProceedWithoutCode}
                style={styles.buttonSecondary}
            >
                I don't have a tutor code
            </button>
        </div>
    )
}

export default StudentSignUpPage
