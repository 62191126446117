import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import config from '../../../../../config'
import AssignmentModal from './AssignmentModal'
import Latex from 'react-latex-next'
import dropdownIcon from '../../../../../assets/images/arrow_white_down.svg'

function TutorHomework() {
    const [students, setStudents] = useState([])
    const [studentAssignments, setStudentAssignments] = useState({})
    const [isLoading, setIsLoading] = useState(false)
  /*   const [isModalOpen, setIsModalOpen] = useState(true) */
    const [currentStudentId, setCurrentStudentId] = useState(null)
    const [newAssignment, setNewAssignment] = useState({
        title: '',
        studentId: currentStudentId,
        /* description: 'Solve the following problems', */
        /* problems: '1 + 1 = ?', */
        deadline: '',
        /* due_date: '2022-12-31', */
        /* example_task: 'Solve the following problems' */
    })
    const apiBaseUrl = config.API_BASE_URL
    const tutor = useSelector((state) => state.auth)
    const tutorId = tutor.userInfo.id
    const [studentIdArray, setStudentIdArray] = useState([]);
    const [studentEmail, setStudentEmail] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [openAssignDropdownId, setOpenAssignDropdownId] = useState(null); // Track open dropdown ID

    const getStudentIdByEmail = async (studentEmail) => {
        const response = await fetch(`${apiBaseUrl}/student/${studentEmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            console.error('Failed to get student ID:', response.statusText)
            return null
        }

        const data = await response.text()

        if (data) {
            console.log('Student data:', data)
            const parsedData = JSON.parse(data)
            console.log('Parsed student data:', parsedData)
            const studentId = parsedData.student._id
            console.log(`Student ID: ${studentId}`)
            return studentId
        }
    }

    const getStudentById = useCallback(
        async (studentId) => {
            const response = await fetch(
                `${apiBaseUrl}/student/id/${studentId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )

            if (!response.ok) {
                console.error('Failed to get student:', response.statusText)
                return null
            }
            const data = await response.json()
            return data.student || null
        },
        [apiBaseUrl]
    )

    const parseStudentIdArray = useCallback(
        async (studentIdArray) => {
            const promises = studentIdArray.map((studentId) => getStudentById(studentId))
            return Promise.all(promises)
        },
        [getStudentById]
    )

    const handleAddStudent = async (studentName) => {
        const studentId = await getStudentIdByEmail(studentName);

        // Check if the student ID was not found
        if (!studentId) {
            alert(`Student with email ${studentName} not found`);
            return;
        }

        // Check if the student is already added, if so, remove them
        if (studentIdArray.includes(studentName)) {
            handleRemoveStudent(studentName);
            return;
        }

        try {
            const response = await fetch(`${apiBaseUrl}/tutor/add-student/${tutorId}/${studentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tutorId: tutorId,
                    studentId: studentId,
                    timestamp: new Date(),
                }),
            });

            if (!response.ok) {
                if (response.status === 409) {
                    console.error('Student already added to this tutor');
                    alert('Student already added to this tutor');
                    window.location.reload();
                }
                return;
            }

            const newStudent = await getStudentById(studentId);
            setStudentIdArray((prevIds) => [...prevIds, studentId]);
            setStudentEmail('');
            console.log(newStudent)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRemoveStudent = async (studentName) => {
        try {
            const response = await fetch(`${apiBaseUrl}/tutor/remove-student/${tutorId}/${studentName}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tutorId: tutorId,
                    studentName: studentName,
                    timestamp: new Date(),
                }),
            });

            if (!response.ok) {
                console.error('Failed to remove student:', response.statusText);
                return;
            }

            // Remove the student from the local state
            setStudentIdArray((prevIds) => prevIds.filter(id => id !== studentName));
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchStudentIds = useCallback(async () => {
        
        setIsLoading(true);
        const response = await fetch(`${apiBaseUrl}/tutor/students/${tutorId}`);

        if (!response.ok) {
            console.error('Failed to fetch students:', response.statusText);
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        console.log(`Student data:`, data)
        setStudentIdArray(data.students);
        setIsLoading(false);
    }, [tutorId, apiBaseUrl]);


    const fetchStudents = useCallback(async () => {
        setIsLoading(true)
        const response = await fetch(`${apiBaseUrl}/tutor/students/${tutorId}`)

        if (!response.ok) {
            console.error('Failed to fetch students:', response.statusText)
            setIsLoading(false)
            return
        }

        const data = await response.json()
        const studentDetails = await parseStudentIdArray(data.students)
        setStudents(studentDetails)
        setIsLoading(false)
    }, [tutorId, apiBaseUrl, parseStudentIdArray])

    useEffect(() => {
        fetchStudentIds();
    }, [fetchStudentIds]);

    useEffect(() => {
        if (studentIdArray.length > 0) {
            fetchStudents();
        }
    }, [studentIdArray, fetchStudents]);


    const fetchAssignments = useCallback(async (studentId) => {
        const response = await fetch(`${apiBaseUrl}/homework/student/${studentId}`)
        if (!response.ok) {
            console.error(`Failed to fetch assignments for student ${studentId}:`, response.statusText)
            return
        }
        const data = await response.json()
        console.log(`Assignments for student ${studentId}:`, data)
        setStudentAssignments((prevAssignments) => ({
            ...prevAssignments,
            [studentId]: data.homework
        }))
    }, [apiBaseUrl])

    useEffect(() => {
        fetchStudents()
    }, [fetchStudents])

    useEffect(() => {
        getStudentById(getStudentById())
        console.log('getting student by id')
    }, [getStudentById])


    /*  const handleFetchAssignments = (studentId) => {
     console.log(`Fetching assignments for student ${studentId}...`)
     if (!studentAssignments[studentId]) {
         fetchAssignments(studentId)
     }
 } */
    const handleCloseModal = () => {
        /* setIsModalOpen(false) */
        setNewAssignment({
            title: '',
            studentId: '',
            description: '', problems: '', deadline: '', due_date: '', example_task: ''
        })
    }
    const handleCreateAssignment = async () => {
        console.log("Creating new assignment...");
        const { title, studentId, problems, deadline } = newAssignment

        console.log('newAssignment', newAssignment);

        // Check if studentId is missing
        if (!studentId) {
            alert('Please add or select a student before creating an assignment.');
            return;
        }

        // Check if deadline is missing
        if (!deadline) {
            alert('Please provide a deadline for the assignment.');
            return;
        }

        if (!title || !studentId || !problems) {
            console.error('Please fill all required fields.')
            return
        }

        console.log(`Creating assignment for student ${currentStudentId}...`)
        console.log(`Assignment data:`, newAssignment)
        const response = await fetch(`${apiBaseUrl}/homework`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...newAssignment, studentId: currentStudentId })
        })

        if (!response.ok) {
            const errorData = await response.json()
            console.error('Failed to create assignment:', errorData.message)
            alert('Failed to create assignment')
            return
        }

        const data = await response.json()
        setStudentAssignments((prevAssignments) => ({
            ...prevAssignments,
            [currentStudentId]: [...(prevAssignments[currentStudentId] || []), data.homework]
        }))

        console.log('New assignment:', data.homework);
        console.log("Generate Example Task here!");
        await handleGenerateExampleTasks(data.homework._id);
        console.log(studentAssignments, '--studentAssignments');
        handleCloseModal(true)
        alert('New assignment created successfully!')
    }

    const handleGenerateExampleTasks = async (homeworkId) => {
        console.log(`Generating example tasks for homework ${homeworkId}...`)
        const response = await fetch(`${apiBaseUrl}/homework/generate-example-task/${homeworkId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })

        if (!response.ok) {
            const errorData = await response.json()
            console.error('Failed to generate example tasks:', errorData.message)
            return
        }

        const data = await response.json()
        console.log(data)
        setStudentAssignments((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments }
            for (const studentId in updatedAssignments) {
                updatedAssignments[studentId] = updatedAssignments[studentId].map(homework =>
                    homework._id === homeworkId ? { ...homework, example_tasks: data.example_tasks } : homework
                )
            }
            return updatedAssignments
        })
    }

    const handleDeleteAssignment = async (homeworkId) => {
        console.log(`Deleting assignment ${homeworkId}...`)
        const response = await fetch(`${apiBaseUrl}/homework/${homeworkId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ homeworkId })
        })

        if (!response.ok) {
            const errorData = await response.json()
            console.error('Failed to delete assignment:', errorData.message)
            return
        }

        setStudentAssignments((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments }
            for (const studentId in updatedAssignments) {
                updatedAssignments[studentId] = updatedAssignments[studentId].filter(homework => homework._id !== homeworkId)
            }
            return updatedAssignments
        })
    }

    const handleMarkAsFinished = async (homeworkId) => {
        console.log(`Marking assignment ${homeworkId} as finished...`)
        const response = await fetch(`${apiBaseUrl}/homework/${homeworkId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ finished: true })
        })

        if (!response.ok) {
            const errorData = await response.json()
            console.error('Failed to mark assignment as finished:', errorData.message)
            return
        }

        const data = await response.json()
        setStudentAssignments((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments }
            const studentId = data.homework.studentId
            updatedAssignments[studentId] = updatedAssignments[studentId].map(homework =>
                homework._id === homeworkId ? { ...homework, finished: true } : homework
            )
            return updatedAssignments
        })
    }

    const [selectedStudent, setSelectedStudent] = useState(students[0]?._id || "");
    const [studentName, setStudentName] = useState(students[0]?._id || "");

    // Log whenever selectedStudent or studentName changes
    useEffect(() => {
        console.log('selectedStudent has changed:', selectedStudent);
    }, [selectedStudent]);

    useEffect(() => {
        console.log('currentStudent has changed:', currentStudentId);
    }, [currentStudentId]);

    useEffect(() => {
        console.log('studentAssignments has changed:', studentAssignments);
    }, [studentAssignments]);

    const handleChange = useCallback(async (id, name) => {
        console.log('---> Selected student:', id, name);
        console.log(selectedStudent, '1 -selected student - ');
        const selectedStudentId = id;
        setStudentName(name)
        setSelectedStudent(selectedStudentId);
        console.log(selectedStudent, '2 - selected student - ');
        setCurrentStudentId(selectedStudent)
        setNewAssignment({ ...newAssignment, studentId: selectedStudent })
        await fetchAssignments(selectedStudentId);
        setIsDropdownOpen(false)
    }, [newAssignment, selectedStudent, fetchAssignments]);

    useEffect(() => {
        setCurrentStudentId(selectedStudent)
    }, [handleChange, selectedStudent])
    const handleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    console.log(studentAssignments)

    const dropdownAssignOpen = (homeworkId) => {
        setOpenAssignDropdownId(openAssignDropdownId === homeworkId ? null : homeworkId); // Toggle dropdown
    }

    const handleAssignTasks = async (homeworkId) => {
        alert(`Assigning tasks for the homework...`);
        console.log(`Assigning tasks for homework ${homeworkId}...`);
        // Close the dropdown after assigning tasks
        setOpenAssignDropdownId(null);
    }

    return (
        <div className='text-white px-5 sm:px-10 py-3 sm:py-5 pt-[110px] sm:pt-[110px]  md:pt-8'>
            <div className={`z-30 relative flex flex-wrap w-fit items-center mr-0 md:mr-52 mt-2 ${students.length > 0 ? '' : ' mb-24'}`}>
                <div className={`relative flex flex-col items-end   ${students.length > 0 ? 'mb-20' : 'hidden'}`}>

                    <div className='flex items-center'>
                        <h1 className='font-lilita md:text-5xl xs:text-4xl text-2xl'>{studentName ? studentName : 'Select student'}</h1>
                        <button className='h-[60px] w-[60px] ml-6 bg-main-color p-4 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] text-center [text-shadow:0px_2px_2px_#00000040]' onClick={handleDropdown}><img height={20} src={dropdownIcon} alt="select student" className={`${isDropdownOpen && 'rotate-180'}`} /></button>
                    </div>

                    {isDropdownOpen && <ul className='bg-[#1B193F] absolute top-20 border border-[#383570] w-fit rounded-xl px-6 py-3 mt-4'>
                        {students.map((student) =>
                        (
                            <li key={student._id} value={student._id} className='text-white text-2xl py-1 bg-[#1B193F] leading-7 [text-shadow:0px_2px_2px_#00000025]'>
                                <button value={student._id} onClick={() => handleChange(student._id, student.name)}>
                                    {student.name}
                                </button>
                            </li>
                        ))}
                    </ul>}

                </div>
            </div>

            <>
                <AssignmentModal
                    isOpen={true}
                    onClose={handleCloseModal}
                    onSubmit={handleCreateAssignment}
                    newAssignment={newAssignment}
                    setNewAssignment={setNewAssignment}
                    currentStudentId={currentStudentId}
                />
                {/* <button
                        className='w-full text-center'
                        onClick={() => handleOpenModal(selectedStudent)}
                    >
                        Create Assignment
                    </button> */}
            </>

            {isLoading ? (
                    <p className='absolute top-32'>Loading...</p>
                ) :
                students.length > 0 ?
                    (
                        students.map((student) => (

                            /* TODO when need show tasks make it visible */
                            <div key={student._id} className={`${currentStudentId === student._id ? 'visible' : 'hidden'}`}>
                                {studentAssignments[student._id] && (
                                    <div className='max-w-[650px] bg-[#090830]/40 rounded-[20px] p-6 sm:p-10 m-auto mb-8 text-white'>
                                        {studentAssignments[student._id].map((homework, homeworkIndex) => (
                                            <div className='rounded-[20px] mb-4' key={homeworkIndex}>
                                                <ul>
                                                    <li className="">
                                                        <div className="flex justify-between items-center mb-5">
                                                            <h4 className="text-[#B1AFCD] text-base font-normal uppercase tracking-[0.32px]">{homework.title}</h4>
                                                            <div className="flex items-center relative">
                                                                <button className="text-2xl font-normal font-lilita [text-shadow:0px_2px_2px_#00000025]
                    bg-[#0E795F] py-2 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(80,227,194)] text-center mr-8"
                                                                    type="submit" onClick={() => handleAssignTasks(homework._id)}>Assign
                                                                </button>
                                                                <button className="flex p-1"
                                                                        onClick={() => dropdownAssignOpen(homework._id)}>
                                                                <span
                                                                    className="w-1 h-1 mr-1 bg-[#59588D] rounded-full"></span>
                                                                    <span
                                                                        className="w-1 h-1 mr-1 bg-[#59588D] rounded-full"></span>
                                                                    <span
                                                                        className="w-1 h-1 bg-[#59588D] rounded-full"></span>
                                                                </button>
                                                                {openAssignDropdownId === homework._id && // Show dropdown only for the selected homework
                                                                    <div
                                                                        className="absolute right-0 top-[-56px] bg-[#1B193F] border border-[#383570] rounded-xl">

                                                                        <button
                                                                            className="block w-full text-base font-normal [text-shadow:0px_2px_2px_#00000025] py-2 px-4"
                                                                            onClick={() => handleDeleteAssignment(homework._id)}>Delete
                                                                        </button>
                                                                        <button
                                                                            className="block w-full text-base font-normal [text-shadow:0px_2px_2px_#00000025] py-2 px-4"
                                                                            onClick={() => handleMarkAsFinished(homework._id)}>Mark as finished
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        {homework.example_tasks ? (
                                                            <ul>
                                                                {homework.example_tasks.map((task, taskIndex) => (
                                                                    <li key={taskIndex}>
                                                                        <Latex>{task}</Latex>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No example tasks</p>
                                                        )}

                                                    </li>
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="max-w-[650px] bg-[#090830] rounded-[20px] mt-10 p-10 m-auto mb-6">
                            <h2 className="font-lilita leading-[30px] font-normal text-5xl mb-12">Add your first
                                student</h2>
                            <div className="flex flex-col items-end">
                                <input
                                    type="text"
                                    placeholder="email@email.com"
                                    onChange={(e) => setStudentEmail(e.target.value)}
                                    name="studentEmail"
                                    className="mb-6 bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 w-full placeholder:text-white font-light"
                                />
                                <button
                                    className="font-lilita md:text-4xl text-xl font-normal bg-main-color py-2 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] text-center [text-shadow:0px_2px_2px_#00000040]"
                                    disabled={isLoading}
                                    onClick={() => handleAddStudent(studentEmail)}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    )}
        </div>
    )
}

export default TutorHomework
