import React from 'react'
import styles from './Landing.styles' // Assuming you have or will create a specific styles file for this component

const Landing = () => {
    return (
        <div style={styles.container}>
            <h1>Welcome to Your Dashboard</h1>
            <p>
                This is your personal learning dashboard. Here you can track
                your progress, view your upcoming tasks, and access all the
                resources you need to succeed in your studies.
            </p>

            <div style={styles.quickLinksContainer}>
                <h2>Quick Links</h2>
                <ul style={styles.quickLinksList}>
                    <li>View My Homework Assignments</li>
                    <li>Access Learning Materials</li>
                    <li>Check My Progress</li>
                    {/* Add more quick links as needed */}
                </ul>
            </div>

            {/* Optionally, add more sections with additional information or widgets */}
        </div>
    )
}

export default Landing
