import React from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navbar, Footer } from './components'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'

import {
    LandingPage,
    AccessCodeEntry,
    StudentLandingPage,
    AccountCreation,
    Dashboard,
    LoginPage,
    TutorRegister,
    AboutPage,
    ContactPage,
    PrivacyPolicy
} from './pages'

function App() {
    return (
        <Router>
            <div className="App bg-bg-blue font-roboto">
            <div className='bg-[rgba(0,0,0,0.5)] min-h-screen relative pb-[174px] px-8'>
                
                <div className='max-w-[1280px] mx-auto'>
                    <Navbar />
                    <div className="">
                        <Routes>
                            {/* Allow only logged-out users to access the Landing Page, Login, and Register */}
                            <Route
                                element={
                                    <ProtectedRoute
                                    redirectIfAuthenticated={true}
                                    redirectTo="/dashboard"
                                    />
                                }
                                >
                                <Route path="/" element={<LandingPage />} />
                                <Route path="/about" element={<AboutPage />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/contact" element={<ContactPage />} />
                                <Route
                                    path="/student-signup"
                                    element={<StudentLandingPage />}
                                    />
                                <Route path="/login" element={<LoginPage />} />
                                <Route
                                    path="/account-creation"
                                    element={<AccountCreation />}
                                    />
                            </Route>
                            <Route
                                path="/tutor-register"
                                element={<TutorRegister />}
                                />

                            {/* Protect the Dashboard and related routes for logged-in users only */}
                            <Route element={<ProtectedRoute redirectTo="/login" />}>
                                <Route
                                    path="/dashboard/*"
                                    element={<Dashboard />}
                                />
                            </Route>

                            {/* Redirect logged-in users from Login/Register to the Dashboard */}
                            <Route
                                element={
                                    <ProtectedRoute
                                    redirectIfAuthenticated={true}
                                    redirectTo="/dashboard"
                                    />
                                }
                                >
                                <Route path="/login" element={<LoginPage />} />
                            </Route>

                            {/* Other Routes */}
                            <Route path="/access" element={<AccessCodeEntry />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
                </div>
            </div>
        </Router>
    )
}

export default App
