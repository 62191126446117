//import React, { useState } from 'react'
//import { useNavigate } from 'react-router-dom'
//import StudentLoginForm from './StudentLoginForm/StudentLoginForm'
//import TutorLoginForm from './TutorLoginForm/TutorLoginForm'
/* import styles from './LoginPage.styles' */
//import classes from './LoginPage.module.css' // import your CSS module
//import studentImage from '../../assets/images/login/student.png'
//import tutorImage from '../../assets/images/login/tutor-800.png'
//import { Modal } from '../../components/index';

function LoginPage() {
    /* const [loginType, setLoginType] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const [openLogin, setOpenLogin] = useState(false);

    const handleLoginSuccess = (userType, userData) => {
        navigate('/dashboard')
    }

    const handleLoginError = (error) => {
        setErrorMessage(error)
        setLoginType('')
    } */

    return (
        
        <div >
            <p className="text-2xl font-bold text-white">Log in to your account</p>
            <p className="text-lg text-white">Welcome back! Please log in to your account to continue.</p>
            {/* <p>
                Don't have an account?{' '}
                <a
                    href="/account-creation"
                    style={{
                        color: 'blue',
                        textDecoration: 'none',
                        cursor: 'pointer',
                    }}
                >
                    Sign up here
                </a>
            </p>
            {errorMessage && <p>{errorMessage}</p>}
            {!loginType && (
                <div>
                    <img
                        src={studentImage}
                        alt="Student Login"
                        style={{ width: '30%', marginRight: '5%' }}
                    />
                    <div>
                        <button
                            onClick={() => setLoginType('student')}
                            className={classes.button} // Apply CSS module class
                        >
                            Student Login
                        </button>
                        <button
                            onClick={() => setLoginType('tutor')}
                            className={classes.button} // Apply CSS module class
                        >
                            Tutor Login
                        </button>
                    </div>

                    <img
                        src={tutorImage}
                        alt="Tutor Login"
                        style={{ width: '40%' }}
                    />
                </div>
            )}
            {loginType === 'student' && (
                <StudentLoginForm
                    onLoginSuccess={(userData) =>
                        handleLoginSuccess('student', userData)
                    }
                    onLoginError={handleLoginError}
                />
            )}
            {loginType === 'tutor' && (
                <Modal open={openLogin} noOvals={false} onClose={() => setOpenLogin(false)}>
                <TutorLoginForm
                    onLoginSuccess={(userData) =>
                        handleLoginSuccess('tutor', userData)
                    }
                    onLoginError={handleLoginError}
                />
                </Modal>
            )} */}
        </div>
    )
}

export default LoginPage
