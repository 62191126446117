import React, { useRef } from 'react'
/* import styles from './TutorHomework.styles' */

const AssignmentModal = ({
                             isOpen,
                             onClose,
                             onSubmit,
                             newAssignment,
                             setNewAssignment,
                             currentStudentId
                         }) => {
    const modalRef = useRef()

    if (!isOpen) return null

    return (
        <div className='max-w-[650px] bg-[#090830] rounded-[20px] p-6 sm:p-10 m-auto mb-6 text-white'>
            <div ref={modalRef} className='bg-[#090830] rounded-[20px] w-full'>
                <h2 className='font-lilita leading-[30px] md:text-5xl text-3xl md:mb-[50px] mb-[50px]'>New Homework</h2>
                <div className='flex flex-col mb-6'>
                    <label className='uppercase md:text-base text-sm mb-[6px] text-[#B1AFCD]' htmlFor="skill">Skill name</label>
                    <input
                        type="text"
                        name='skill'
                        placeholder="E.g. Solve a quadratic equation using square roots"
                        value={newAssignment.title}
                        className='rounded-lg  md:text-base text-sm bg-[#263768] py-[9px] pl-[18px] placeholder:text-gray placeholder:font-light font-light'
                        onChange={(e) => setNewAssignment({ ...newAssignment, title: e.target.value, studentId: currentStudentId })}
                        />
                </div>
                {/* <textarea
                    placeholder="Description"
                    value={newAssignment.description}
                    onChange={(e) => setNewAssignment({ ...newAssignment, description: e.target.value })}
                    className='mb-[24px]'
                /> */}
                <div className='flex flex-col mb-6'>
                    <label className='uppercase  md:text-base text-sm mb-[6px] text-[#B1AFCD]' htmlFor="exampletask">Example task</label>
                    <textarea
                        placeholder="E.g. Solve for v. v2 = 81"
                        name='exampletask'
                        value={newAssignment.problems}
                        onChange={(e) => setNewAssignment({ ...newAssignment, problems: e.target.value })}
                        className='rounded-lg  md:text-base text-sm bg-[#263768] py-[9px] pl-[18px] placeholder:text-gray placeholder:font-light font-light'
                        />
                </div>
                <div className='flex flex-col mb-6'>
                    <label className='uppercase  md:text-base text-sm mb-[6px] text-[#B1AFCD]' htmlFor="date">Deadline</label>
                    <input
                        type="date"
                        value={newAssignment.deadline}
                        onChange={(e) => setNewAssignment({ ...newAssignment, deadline: e.target.value })}
                        className='rounded-lg  md:text-base text-sm bg-[#263768] py-[9px] pl-[18px] placeholder:text-gray placeholder:font-light font-light'
                    />
                </div>
               {/*  <input
                    type="text"
                    placeholder="Example Task"
                    value={newAssignment.example_task}
                    onChange={(e) => setNewAssignment({ ...newAssignment, example_task: e.target.value })}
                    style={styles.input}
                /> */}
                <div className='w-full text-end'>
                    <button onClick={onSubmit}  className='font-lilita md:text-4xl text-xl font-normal bg-main-color py-2 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] text-center [text-shadow:0px_2px_2px_#00000040]' type='submit'
                    >Create</button>
                </div>
                {/* <button onClick={onClose} style={styles.button}>Cancel</button> */}
            </div>
        </div>
    )
}

export default AssignmentModal
