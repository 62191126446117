import React, { useState } from 'react'
import styles from './Goals.styles' // Adjust the import path as needed
import CurrentGoals from './components/CurrentGoals/CurrentGoals'
import GoalsRoadmap from './components/GoalsRoadmap/GoalsRoadmap'
import CreateGoal from './components/CreateGoal/CreateGoal'

const Goals = () => {
    const [view, setView] = useState('current')

    const handleViewChange = (newView) => {
        setView(newView)
    }

    return (
        <div style={{ width: '100%', paddingBottom: '20px' }}>
            <div style={styles.navigation}>
                <button
                    onClick={() => handleViewChange('current')}
                    style={styles.navButton}
                >
                    Your Current Goals
                </button>
                <button
                    onClick={() => handleViewChange('roadmap')}
                    style={styles.navButton}
                >
                    Roadmap
                </button>
                <button
                    onClick={() => handleViewChange('create')}
                    style={styles.navButton}
                >
                    Create a New Goal
                </button>
            </div>

            {view === 'current' && <CurrentGoals />}
            {view === 'roadmap' && <GoalsRoadmap />}
            {view === 'create' && <CreateGoal />}
        </div>
    )
}

export default Goals
