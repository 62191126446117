import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import config from '../../../../../config'
import ellipse from '../../../../../assets/images/ellipse_new.svg'

function TutorDashboardView() {
    // get user from redux store
    const idTutor = useSelector((state) => state.auth.userInfo)
    console.log(idTutor.name)

    // Example student data, replace with actual data fetching
    const [studentIdArray, setStudentIdArray] = useState([])
    const [students, setStudents] = useState([])
    const apiBaseUrl = config.API_BASE_URL
    const [isLoading, setIsLoading] = useState(false)
    //const [dataStudent, setDataStudent] = useState([])
    const [studentEmail, setStudentEmail] = useState('')
    const [studentName, setStudentName] = useState('')
    const [studentGrade, setStudentGrade] = useState('')
    const [emailWarning, setEmailWarning] = useState('')
    // get tutorId from redux store, this assumes the tutor is logged in
    const tutor = useSelector((state) => state.auth)
    const tutorId = tutor.userInfo.id
 /* const [parsedStudentData, setParsedStudentData] = useState() */
    const getStudentIdByEmail = async (studentEmail) => {
        const response = await fetch(`${apiBaseUrl}/student/${studentEmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            console.error('Failed to get student ID:', response.statusText)
            return null
        }

        const data = await response.text()

        if (data) {
            //console.log('Student data:', data)
            const parsedData = JSON.parse(data)
            //console.log('Parsed student data:', parsedData)
            const studentId = parsedData.student._id
            //console.log(`Student ID: ${studentId}`)
            return studentId
        }
    }

    const getStudentById = useCallback(
        async (studentId) => {
            const response = await fetch(
                `${apiBaseUrl}/student/id/${studentId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )

            if (!response.ok) {
                console.error('Failed to get student:', response.statusText)
                return null
            }
            const data = await response.json()
            //setDataStudent(JSON.stringify(data.student))

            if (data) {
                console.log('Student sdf data:', data)
                return data.student
            } else {
                console.error('No student data found')
                return null
            }
        },
        [apiBaseUrl],
    )
    /* INFO here is all student info - list all students */
    //console.log('data student:' + dataStudent)
    const parseStudentIdArray = useCallback(
        async (studentIdArray) => {
            const promises = studentIdArray.map((studentId) => {
                return getStudentById(studentId)
            })
            return Promise.all(promises)
        },
        [getStudentById],
    )

    const setStudentNameInBackend = async (studentId, StudentName) => {
        fetch(
            `${apiBaseUrl}/student/set-name`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Student_id: studentId,
                    name: StudentName, // If you want to include a timestamp
                }),
            },
        )
            .then((response) => {
                // response.ok means status is 200-299
                if (!response.ok) {
                    console.log(response)
                }
                return response.text()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    const setStudentGradeInBackend = async (studentId, StudentGrade) => {
        fetch(
            `${apiBaseUrl}/student/set-grade`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Student_id: studentId,
                    grade: StudentGrade, // If you want to include a timestamp
                }),
            },
        )
            .then((response) => {
                // response.ok means status is 200-299
                if (!response.ok) {
                    console.log(response)
                }
                return response.text()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }


    const handleAddStudent = async (studentEmail, studentName, studentGrade) => {

        const studentId = await getStudentIdByEmail(studentEmail)

        console.log(`Student Name: ${studentName}`);
        console.log(`Student Grade: ${studentGrade}`);


        if (!studentId) {
            console.error(`Student with email ${studentEmail} not found`)
                alert(`Student with email ${studentEmail} not found. Please ask your student to download the Joyed app and create an account first.`);
            return
        }
        console.log(`Student email: ${studentEmail}, Student ID: ${studentId}`)
        // url: "/api/v1/tutor/add-student/:tutorId/:studentId",
        fetch(
            `${apiBaseUrl}/tutor/add-student/${tutorId}/${studentId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tutorId: tutorId,
                    studentId: studentId,
                    timestamp: new Date(), // If you want to include a timestamp
                }),
            },
        )
            .then((response) => {
                // response.ok means status is 200-299
                if (!response.ok) {
                    console.log(response)
                    if (response.status === 409) {
                        console.error('Student already added to this tutor');
                        alert('Student already added to this tutor');
                    }
                }

                if (response.ok)
                {
                    console.log('Student added successfully');
                    alert('Student successfully added. Go to Homework tab to create your first homework.');
                    setStudentNameInBackend(studentId, studentName);
                    setStudentGradeInBackend(studentId, studentGrade);
                }
                return response.text()
            })
            .then((data) => {
                if (data) {
                    const parsedData = JSON.parse(data)
                    setStudents([...students, parsedData])
                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    const fetchStudentIds = useCallback(async () => {
        setIsLoading(true) // Start loading
        const response = await fetch(`${apiBaseUrl}/tutor/students/${tutorId}`)

        if (!response.ok) {
            console.error('Failed to fetch students:', response.statusText)
            setIsLoading(false) // Stop loading on error
            return
        }

        const data = await response.json()
        console.log(`Student data:`, data)
        setStudentIdArray(data.students)
        setIsLoading(false) // Stop loading on success
    }, [tutorId, apiBaseUrl])

   /*  const fetchStudents = useCallback(async () => {
        setIsLoading(true) // Start loading
        const studentData = await parseStudentIdArray(studentIdArray)
        setStudentArray(studentIdArray)
        setStudents(studentData)
        console.log(`Students array:`, studentIdArray)
        console.log(`Parsed students:`, studentData)
        setIsLoading(false) 
    }, [parseStudentIdArray, studentIdArray]) */
    const fetchStudents = useCallback(async () => {
        setIsLoading(true)
        const response = await fetch(`${apiBaseUrl}/tutor/students/${tutorId}`)

        if (!response.ok) {
            console.error('Failed to fetch students:', response.statusText)
            setIsLoading(false)
            return
        }

        const data = await response.json()
        const studentDetails = await parseStudentIdArray(data.students)
        setStudents(studentDetails)
        setIsLoading(false)
    }, [tutorId, apiBaseUrl, parseStudentIdArray])


/*     useEffect(() => {
        handleAddStudent()
    }, [handleAddStudent]) */

    useEffect(() => {
        fetchStudentIds()
    }, [fetchStudentIds])

    useEffect(() => {
        if (studentIdArray.length > 0) {
            fetchStudents();
        }
    }, [studentIdArray, fetchStudents]);

    useEffect(() => {
        fetchStudents()
    }, [fetchStudents])
useEffect(() => {
        getStudentById(getStudentById())
        console.log('getting student by id')
    }, [getStudentById])

    /*  const handleRemoveStudent = (studentId) => {
         // Function to remove a student, be sure to confirm before deletion
         setStudents(students.filter((student) => student.id !== studentId))
     } */
    /* console.log(parsedStudentData) */

    return (
        <div className='px-4 sm:px-10 py-2 sm:py-5 pt-[110px] sm:pt-[110px]  md:pt-8' >
            <h1 className='font-lilita md:text-[48px] font-normal text-4xl mb-10'>Dashboard</h1>

            {/* <div>{studentArray}</div> */}
            {/*             <section style={styles.section}>
                <h3>Upcoming Sessions</h3>
                <ul style={styles.list}>
                    {upcomingSessions.map((session, index) => (
                        <li key={index} style={styles.listItem}>
                            {session.date}: {session.student} -{' '}
                            {session.subject}
                        </li>
                    ))}
                </ul>
            </section> */}
            {isLoading ? (
                <p>Loading...</p>
            ) :
                (
                    <>
                    <div className='bg-[#292557] sm:mt-20 max-w-[668px] mx-auto text-center rounded-[20px] drop-shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] p-6 relative min-h-[300px]'>
                        <div className='absolute w-full flex justify-center -mt-6'>
                            <img className=' ml-5' src={ellipse} alt="background" />
                            {/* <img className='max-w-[207px] mr-5' src={bgL} alt="background" /> */}
                        </div>
                        <div className='w-full items-center relative z-10 my-10'>
                            <h2 className='font-lilita leading-[30px] md:text-[50px] font-normal text-4xl mb-10'>Add students</h2>

                            <div className=" flex flex-col m-auto max-w-[344px]">
                                <input
                                    type="email"
                                    name="email"
                                    value={studentEmail}
                                    onChange={(e) => {
                                        setStudentEmail(e.target.value);
                                        if (!/\S+@\S+\.\S+/.test(e.target.value)) {
                                            setEmailWarning('Please enter a valid email address');
                                        } else {
                                            setEmailWarning('');
                                        }
                                    }}
                                    placeholder="Email"
                                    required
                                    className="bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4  py-4 placeholder:text-gray font-light mb-4"
                                />
                                {emailWarning && <p className="text-light-red">{emailWarning}</p>}
                                <input
                                    type="text"
                                    name="name"
                                    value={studentName}
                                    onChange={(e) => setStudentName(e.target.value)}
                                    placeholder="Name"
                                    required
                                    className="bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4  py-4 placeholder:text-gray font-light mb-4"
                                />
                                <input
                                    type="number"
                                    name="grade"
                                    value={studentGrade}
                                    onChange={(e) => setStudentGrade(e.target.value)}
                                    placeholder="Grade"
                                    required
                                    className="bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4  py-4 placeholder:text-gray font-light mb-4"
                                />
                                <button type="submit" disabled={isLoading}
                                        onClick={() => handleAddStudent(studentEmail, studentName, studentGrade)}
                                        className="font-lilita md:text-4xl text-xl font-normal bg-main-color py-3 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] mt-2 [text-shadow:0px_2px_2px_#00000040] w-full">
                                    {isLoading ? 'Invite...' : 'Add'}
                                </button>
                            </div>
                        </div>
                    </div>

                        <br></br>

                            <section className="bg-[#090830] rounded-[20px] md:p-10 p-6">
                                <h3 className="uppercase text-[#B1AFCD] sm:text-base text-sm mb-6">Recent
                                    Assignments</h3>
                                <ul>
                                    {students.map((students) => (
                                        <li key={students._id}
                                            className='md:text-2xl sm:text-[18px] text-base text-[#7877B1] md:mb-6 mb-10 last:mb-0'>
                                        <div className='flex justify-between'>
                                            <div className='flex md:items-center md:flex-row flex-col'>
                                                <p className='w-5 h-5 fixed mr-3 border rounded-full border-[#373467] hidden md:flex'></p>
                                                <p className='mr-14 md:ml-9 ml-0 md:mb-0 mb-2'>{students.name}</p>
                                                <p>Title</p>
                                            </div>
                                            <div className='flex flex-col md:flex-row md:items-center items-end ml-3'>
                                                <p className={`text-lg rounded-full h-fit bg-opacity-20 py-1 px-3 mb-2 md:mb-0 -mr-1 md:mr-[60px] bg-[#50E3C2] text-[#50E3C2]`}>Completed</p>
                                                <p>Due Date</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    </>
                )}
            {/* Add more sections as needed */}
        </div>
    )
}

export default TutorDashboardView
