const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        marginTop: '2rem',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px', // Adjust based on your preference
    },
    heading: {
        textAlign: 'center',
        color: '#333', // Consider using a variable or import for colors
        marginBottom: '20px',
    },
    label: {
        fontSize: '16px',
        margin: '10px 0',
        color: '#333', // Consistent text color
        display: 'block', // Ensure the label appears above its input
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        margin: '5px 0 15px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    formGroup: {
        marginBottom: '15px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#5667fd', // Primary color
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
        alignSelf: 'center',
    },
}

export default styles
