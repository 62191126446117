import colors from '../../../styles/colors'

// AccountCreation.styles.js
const styles = {
    container: {
        padding: '20px',
        maxWidth: '400px',
        margin: '40px auto',
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    input: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px',
        color: 'white',
        backgroundColor: colors.primary,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
    },
}

export default styles
