import React, { useState } from 'react'
import Latex from 'react-latex-next'
import 'katex/dist/katex.min.css'

const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1000',
    backgroundColor: '#FFF',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '80%',
    maxHeight: '80vh',
    overflowY: 'auto',
}

const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
}

const buttonStyle = {
    cursor: 'pointer',
    padding: '10px 20px',
    margin: '10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
}

const optionStyle = {
    cursor: 'pointer',
    padding: '10px',
    margin: '5px 0',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'transparent',
    color: 'black',
    listStyleType: 'none', // Remove list bullets
}

const selectedOptionStyle = {
    backgroundColor: '#D3D3D3',
    color: 'black',
}

const correctOptionStyle = {
    backgroundColor: '#90EE90',
}

const incorrectOptionStyle = {
    backgroundColor: '#FFB6C1',
}

function Modal({ evaluationData, onClose }) {
    const [selectedAnswers, setSelectedAnswers] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [score, setScore] = useState(0)

    const handleOptionClick = (questionIndex, optionIndex) => {
        if (!submitted) {
            setSelectedAnswers((prev) => ({
                ...prev,
                [questionIndex]: optionIndex,
            }))
        }
    }

    const handleSubmit = () => {
        let newScore = 0
        evaluationData.questions.forEach((question, index) => {
            const selectedAnswerIndex = selectedAnswers[index]
            if (
                question.options[selectedAnswerIndex] === question.correctAnswer
            ) {
                newScore++
            }
        })
        setScore(newScore)
        setSubmitted(true)
    }

    const isCorrect = (questionIndex, optionIndex) => {
        return (
            evaluationData.questions[questionIndex].correctAnswer ===
            evaluationData.questions[questionIndex].options[optionIndex]
        )
    }

    return (
        <>
            <div style={overlayStyle} onClick={onClose} />
            <div style={modalStyle}>
                <h2>Evaluation Details</h2>
                {evaluationData.questions.map((question, questionIndex) => (
                    <div key={questionIndex}>
                        <h3>Question {questionIndex + 1}.</h3>
                        <p>
                            {/* Replace // with / to fix the syntax error and $ with $$ to fix the rendering error */}
                            <Latex>
                                {question.questionText
                                    .replace(/\/\//g, '/')
                                    .replace(/\$/g, '$$')}
                            </Latex>
                        </p>
                        <ul style={{ padding: 0 }}>
                            {question.options.map((option, optionIndex) => (
                                <li
                                    key={optionIndex}
                                    onClick={() =>
                                        handleOptionClick(
                                            questionIndex,
                                            optionIndex,
                                        )
                                    }
                                    style={{
                                        ...optionStyle,
                                        ...(selectedAnswers[questionIndex] ===
                                            optionIndex && selectedOptionStyle),
                                        ...(submitted &&
                                            isCorrect(
                                                questionIndex,
                                                optionIndex,
                                            ) &&
                                            correctOptionStyle),
                                        ...(submitted &&
                                            selectedAnswers[questionIndex] ===
                                                optionIndex &&
                                            !isCorrect(
                                                questionIndex,
                                                optionIndex,
                                            ) &&
                                            incorrectOptionStyle),
                                        ...(submitted && { cursor: 'default' }), // Make unclickable after submit
                                    }}
                                >
                                    <Latex>{option.replace(/\$/g, '$$')}</Latex>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
                {!submitted ? (
                    <button onClick={handleSubmit} style={buttonStyle}>
                        Submit
                    </button>
                ) : (
                    <div>
                        <p>
                            Your score: {score}/
                            {evaluationData.questions.length}
                        </p>
                        <button onClick={onClose} style={buttonStyle}>
                            Close
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default Modal
