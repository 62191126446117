// AccessCodeEntry.jsx
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './AccessCodeEntry.styles'

function AccessCodeEntry() {
    const [accessCode, setAccessCode] = useState('')
    const navigate = useNavigate()

    const handleAccessCodeSubmit = (e) => {
        e.preventDefault()
        navigate('/dashboard') // Adjust based on your routing
    }

    return (
        <div style={styles.entryContainer}>
            <h2>Enter Your Access Code</h2>
            <form onSubmit={handleAccessCodeSubmit}>
                <input
                    type="text"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    placeholder="Access Code"
                    style={styles.input}
                    required
                />
                <button type="submit" style={styles.button}>
                    Submit
                </button>
            </form>
        </div>
    )
}

export default AccessCodeEntry
