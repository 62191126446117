import React from 'react'
import onboarding1 from '../../../../assets/images/1Onboarding.png'
import onboarding2 from '../../../../assets/images/2Onboardingv3.png'
import onboarding3 from '../../../../assets/images/3Onboardingv5.png'
import onboarding4 from '../../../../assets/images/4Onboardingv3.png'
import ovalScreen1 from '../../../../assets/images/oval-screen1.svg'
import ovalScreen2 from '../../../../assets/images/oval-screen2.svg'
import ovalScreen3 from '../../../../assets/images/oval-screen3.svg'
import ovalScreen4 from '../../../../assets/images/oval-screen4.svg'

function HowStudentsLearn() {

    return (
        <div className='pb-[100px] mt-[100px]'>
            <h1 className='font-lilita font-normal md:text-6xl text-3xl md:mb-[48px] mb-8'>How Students Learn</h1>
            <p className='md:text-[22px] text-lg mb-[48px] font-normal leading-[30px]'>Students engage in highly personalized learning journeys, setting their own study goals</p>
            <div className=' flex flex-wrap gap-7 justify-center md:justify-between'>
                <div className='max-w-[286px] text-center flex flex-col w-52 md:w-full'>
                    <div className='flex flex-wrap justify-center relative'>
                        <img
                            className='px-12 absolute -bottom-20 max-w-[286px]'
                            src={ovalScreen1}
                            alt="background"
                        />
                        <img
                            src={onboarding1}
                            alt="how student learn screen 1"
                            width={286}
                            className='w-full max-w-[286px] h-auto rounded-[15px] shadow-[0_4px_60px_0_rgba(0,0,0,0.75)]'
                        />
                    </div>
                    <div className='z-10 max-w-[246px] m-auto mt-48'>
                        <h3 className='font-lilita max-w-[236px] m-auto text-[28px] text-center mb-4 font-normal -mt-44'>Personalized Tasks</h3>
                        <p className='leading-[30px]text-center font-normal text-base'>Students go through onboarding survey to customize tasks and study process</p>
                    </div>
                </div>


                <div className='max-w-[286px] flex flex-col mt-0 sm:mt-[225px] w-52 md:w-full'>
                    <div className='flex flex-wrap justify-center  relative'>
                        <img
                            className='px-12 absolute -bottom-20 max-w-[286px] opacity-50'
                            src={ovalScreen2}
                            alt="background"
                        />
                        <img
                            src={onboarding2}
                            alt="how student learn screen 1"
                            width={286}
                            className='w-full max-w-[286px] h-auto rounded-[15px] shadow-[0_4px_60px_0_rgba(0,0,0,0.75)]'
                        />
                    </div>
                    <div className='z-10 max-w-[246px] m-auto'>
                        <h3 className='font-lilita max-w-[236px] m-auto text-[28px] text-center mb-4'>Student Personas</h3>
                        <p className='text-center text-base  font-normal leading-[30px]'>Personas are built based on the latest science and assessed motivation level</p>
                    </div>
                </div>



                <div className='max-w-[286px] flex flex-col w-52 md:w-full'>
                    <div className='flex flex-wrap justify-center  relative'>
                        <img
                        className='px-12 absolute -bottom-20 max-w-[286px] opacity-50'
                            src={ovalScreen3}
                            alt="background"
                        />
                        <img
                            src={onboarding3}
                            alt="how student learn screen 1"
                            width={286}
                            className='w-full max-w-[286px] h-auto rounded-[15px] shadow-[0_4px_60px_0_rgba(0,0,0,0.75)]'
                        />
                    </div>
                    <div className='z-10 max-w-[246px] m-auto  mt-48'>
                        <h3 className='font-lilita max-w-[236px] m-auto text-[28px] text-center mb-4  -mt-44'>AI generates personalized quizzes based on tutor input</h3>
                        <p className='text-center text-base'>Students practice tasks and use their special superpowers to achieve their own study goals</p>
                    </div>
                </div>



                <div className='max-w-[286px] flex flex-col  mt-0 sm:mt-[225px] w-52 md:w-full'>
                    <div className='flex flex-wrap justify-center  relative'>
                        <img
                        className='px-12 absolute -bottom-20 max-w-[286px] opacity-50'
                            src={ovalScreen4}
                            alt="background"
                        />
                        <img
                            src={onboarding4}
                            alt="how student learn screen 1"
                            width={286}
                            className='w-full max-w-[286px] h-auto rounded-[15px] shadow-[0_4px_60px_0_rgba(0,0,0,0.75)]'
                        />
                    </div>
                    <div className='z-10 max-w-[236px] m-auto '>
                        <h3 className='font-lilita max-w-[236px] m-auto text-[28px] text-center mb-4  mt-7'>Daily and Journey Goals
                        </h3>
                        <p className='text-center text-base  font-normal leading-[30px]'>Study goals are gamified and broken into bite-sized chunks</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowStudentsLearn
