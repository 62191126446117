// Materials.js
import React from 'react'
import styles from './Materials.styles' // Assuming you will create a styles file for this component

const learningMaterials = [
    {
        id: 1,
        title: 'Introduction to Trigonometry',
        type: 'Video',
        link: 'https://example.com/video/trigonometry-intro',
    },
    {
        id: 2,
        title: 'Trigonometry Practice Problems',
        type: 'Document',
        link: 'https://example.com/docs/trigonometry-problems',
    },
    {
        id: 3,
        title: 'Understanding Sine and Cosine',
        type: 'Article',
        link: 'https://example.com/articles/sine-and-cosine',
    },
    // Add more materials as needed
]

const Materials = () => {
    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Learning Materials</h2>
            <ul style={styles.list}>
                {learningMaterials.map((material) => (
                    <li key={material.id} style={styles.item}>
                        <strong>{material.title}</strong> -{' '}
                        <em>{material.type}</em>
                        <br />
                        <a
                            href={material.link}
                            style={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Access Material
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Materials
