const config = {
    development: {
        API_BASE_URL: 'https://webapp-be-development.up.railway.app/api/v1',
        API_BASE_URL2: 'https://webapp-be-development.up.railway.app/api/v2',
    },
    staging: {
        API_BASE_URL: 'https://webapp-be-staging.up.railway.app/api/v1',
        API_BASE_URL2: 'https://webapp-be-staging.up.railway.app/api/v2',
    },
    production: {
        API_BASE_URL: 'https://webapp-be-production.up.railway.app/api/v1',
        API_BASE_URL2: 'https://webapp-be-production.up.railway.app/api/v2',
    },
    test: {
        API_BASE_URL: 'https://test-api-url.com',
    },
}

let environment;

console.log('Environment Variables:');
Object.keys(process.env).forEach(key => {
    console.log(`${key}: ${process.env[key]}`);
});

if (process.env.REACT_APP_NODE_ENV === 'development') {
    environment = 'development';
} else if (process.env.REACT_APP_NODE_ENV === 'staging'){
    environment = 'staging';
} else
    environment = 'production';

console.log('Environment:::', environment);

export default config[environment]
