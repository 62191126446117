import { useState } from 'react'

const problemTemplates = [
    {
        problem: (angle) => `Calculate the sine of ${angle}°.`,
        answer: (angle) => {
            let result = Math.sin((angle * Math.PI) / 180).toFixed(2)
            return result === '0.00' || result === '1.00' || result === '-1.00'
                ? parseFloat(result).toString()
                : result
        },
    },
    {
        problem: (angle) => `Calculate the cosine of ${angle}°.`,
        answer: (angle) => {
            let result = Math.cos((angle * Math.PI) / 180).toFixed(2)
            return result === '0.00' || result === '1.00' || result === '-1.00'
                ? parseFloat(result).toString()
                : result
        },
    },
    {
        problem: (angle) => `Calculate the tangent of ${angle}°.`,
        answer: (angle) => {
            let result = Math.tan((angle * Math.PI) / 180).toFixed(2)
            return result === '0.00' ||
                result === '1.00' ||
                result === '-1.00' ||
                result === 'Infinity' ||
                result === '-Infinity'
                ? parseFloat(result).toString()
                : result
        },
    },
    // Add more templates as needed
]

function TrigonometryProblemsPage() {
    const [problem, setProblem] = useState('')
    const [currentAnswer, setCurrentAnswer] = useState('') // Correct answer for the current problem
    const [userAnswer, setUserAnswer] = useState('') // User's answer
    const [feedback, setFeedback] = useState('') // Feedback message

    const angles = [0, 30, 45, 60, 90] // Possible angles for trigonometry problems

    const [correctAnswers, setCorrectAnswers] = useState(0)
    const totalProblems = 5 // Total number of problems to solve
    const [problemsSolved, setProblemsSolved] = useState(0) // Track the number of problems solved

    const generateProblem = () => {
        const problemIndex = Math.floor(Math.random() * problemTemplates.length)
        const angleIndex = Math.floor(Math.random() * angles.length)
        const problem = problemTemplates[problemIndex].problem(
            angles[angleIndex],
        )
        const answer = problemTemplates[problemIndex].answer(angles[angleIndex])
        setProblem(problem)
        setCurrentAnswer(answer)
        setUserAnswer('') // Reset user's answer
        setFeedback('') // Reset feedback
    }

    const checkAnswer = () => {
        if (currentAnswer === userAnswer.trim()) {
            setFeedback('Correct!')
            setCorrectAnswers((prev) => prev + 1) // Increment the correct answer count
            setProblemsSolved((prev) => prev + 1) // Update problems solved
            if (problemsSolved < totalProblems - 1) {
                nextProblem() // Automatically load the next problem if not yet done
            } else {
                setFeedback('All problems solved!')
            }
        } else {
            setFeedback(`Incorrect. The correct answer is ${currentAnswer}.`)
        }
    }

    const nextProblem = () => {
        generateProblem() // Call the existing problem generation function
        setUserAnswer('') // Reset user's answer
        setFeedback('') // Reset feedback
    }

    const progressBarStyle = {
        height: '20px',
        width: `${(problemsSolved / totalProblems) * 100}%`,
        backgroundColor: 'green',
        textAlign: 'center',
        color: 'white',
        lineHeight: '20px',
    }

    return (
        <div>
            <h2>Trigonometry Problem Generator</h2>
            <div>
                Correct Answers: {correctAnswers} / {totalProblems}
            </div>
            <div style={{ width: '100%', backgroundColor: '#ddd' }}>
                <div
                    style={progressBarStyle}
                >{`${Math.round((problemsSolved / totalProblems) * 100)}%`}</div>
            </div>
            {problemsSolved < totalProblems ? (
                <div>
                    <button onClick={generateProblem} disabled={problem !== ''}>
                        Generate Problem
                    </button>
                    {problem && (
                        <div>
                            <p>{problem}</p>
                            <input
                                type="text"
                                value={userAnswer}
                                onChange={(e) => setUserAnswer(e.target.value)}
                                placeholder="Enter your answer"
                            />
                            <button onClick={checkAnswer}>Submit Answer</button>
                            <p>{feedback}</p>
                        </div>
                    )}
                </div>
            ) : (
                <p>All problems solved! Great job!</p>
            )}
        </div>
    )
}

export default TrigonometryProblemsPage
