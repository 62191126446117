const styles = {
    dashboardContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minHeight: '100vh',
    },
    dashboardContent: {
        flexGrow: 1,
        padding: '20px',
        backgroundColor: '#fff', // Consider matching this with your design
    },
    section: {
        marginBottom: '20px',
    },
    // If you are using CSS classes and want to adapt these for use in a .css file:
    // .dashboard-container { ... }
    // .dashboard-content { ... }
    // .section { ... }
}

export default styles
