// PastTutors.js
import React, { useState, useEffect } from 'react'

function PastTutors() {
    const [tutorHistory, setTutorHistory] = useState([])

    useEffect(() => {
        const fetchTutorHistory = async () => {
            const fetchedTutorHistory = [
                {
                    tutorName: 'Alex Johnson',
                    subject: 'Mathematics',
                    goalsCompleted: [
                        {
                            goalTitle: 'Understand Algebra Basics',
                            completionDate: '2023-03-15',
                        },
                        {
                            goalTitle: 'Achieve Proficiency in Geometry',
                            completionDate: '2023-05-10',
                        },
                    ],
                    sessions: 12,
                    startDate: '2023-01-01',
                    endDate: '2023-06-01',
                },
                // More past tutors as needed
            ]
            setTutorHistory(fetchedTutorHistory)
        }

        fetchTutorHistory()
    }, [])

    return (
        <div>
            <h2>Past Tutors and Completed Goals</h2>
            {tutorHistory.length ? (
                tutorHistory.map((tutor, index) => (
                    <div key={index}>
                        <h3>
                            {tutor.tutorName} - {tutor.subject}
                        </h3>
                        <p>Sessions: {tutor.sessions}</p>
                        <p>
                            Period: {tutor.startDate} to {tutor.endDate}
                        </p>
                        <h4>Completed Goals:</h4>
                        <ul>
                            {tutor.goalsCompleted.map((goal, goalIndex) => (
                                <li key={goalIndex}>
                                    {goal.goalTitle} - Completed on{' '}
                                    {goal.completionDate}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))
            ) : (
                <p>No past tutors found.</p>
            )}
        </div>
    )
}

export default PastTutors
