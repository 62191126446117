import React, { useState } from 'react'

function ContactPage() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /* TOTO email sendind providor */
    /* TOTO add error message, when not sent */
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data submitted:', formData);
        setFormData({
            name: '',
            email: '',
            message: '',
        })
        alert('Your email has been sent. We will get back to you in 2 business days.')
    }

    return (
        <div className='text-white md:mt-[120px] sm:mt-[70px] mt-[40px] mb-16 max-w-[650px] m-auto'>
            <h1 className='font-lilita  md:text-6xl sm:text-3xl text-2xl md:mb-12 mb-8'>Contact us</h1>
            <form className='mb-16 bg-[#090830] rounded-[20px] md:px-10 px-6 py-6' onSubmit={handleSubmit}>
                <div className='flex flex-col mb-6'>
                    <label className='uppercase mb-[6px] text-[#B1AFCD] md:text-base ms:text-sm text-xs' htmlFor="name">Name</label>
                    <input className='rounded-lg bg-[#263768] py-[9px] pl-[18px] placeholder:text-white placeholder:font-light font-light sm:text-lg text-sm' type="text" id='name' name='name' placeholder='Name' value={formData.name} onChange={handleChange} />
                </div>
                <div className='flex flex-col mb-6'>
                    <label className='uppercase mb-[6px] text-[#B1AFCD] md:text-base ms:text-sm text-xs' htmlFor="email">Email*</label>
                    <input className='rounded-lg bg-[#263768] py-[9px] pl-[18px] placeholder:text-white placeholder:font-light font-light sm:text-lg text-sm'  type="email" id='email' name='email' placeholder='Email' value={formData.email} onChange={handleChange} required />
                </div>
                <div className='flex flex-col mb-6'>
                    <label className='uppercase mb-[6px] text-[#B1AFCD] md:text-base ms:text-sm text-xs' htmlFor="message">Message</label>
                    <input className='rounded-lg bg-[#263768] py-[9px] pl-[18px] placeholder:text-white placeholder:font-light font-light sm:text-lg text-sm'  id='message' type='text' name='message' placeholder='Message' value={formData.message} onChange={handleChange} />
                </div>
                <div className='flex justify-end'>
                    <button className='font-lilita md:text-4xl text-xl font-normal bg-main-color sm:py-3 py-2 sm:px-6 px-3 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] [text-shadow:0px_2px_2px_#00000040] ' type='submit'>Send</button>
                </div>
            </form>
            <div className=' md:text-[22px] sm:text-lg text-base text-center'>
                <p className='mb-7 font-bold'>Joyed OÜ</p>
                <p className='underline'><a href='mailto:hanna@joyed.ai'>hanna@joyed.ai</a></p>
                <p className='underline'><a href='tel:+37253986299'>+372 5398 6299</a></p>
                <p>reg no 16989993 </p>
                <p>Saue 9/2-9, 10612 Tallinn Estonia</p>
            </div>
        </div>
    )
}

export default ContactPage
