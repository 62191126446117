import { actionTypes } from './authActions'

const initialState = {
    isLoggedIn: false,
    userRole: null,
    userInfo: null,
}

export const authReducer = (state = initialState, action) => {
    console.log('Action received:', action)
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                userRole: action.payload.role,
                userInfo: {
                    email: action.payload.email,
                    name: action.payload.name,
                    id: action.payload.id,
                },
            }
        case actionTypes.LOGOUT:
            return { ...initialState }
        case actionTypes.SET_ROLE:
            return {
                ...state,
                userRole: action.payload,
            }
        default:
            return state
    }
}
