import React, { useState } from 'react'

function AddTutor() {
    const [code, setCode] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('Adding tutor with code:', code)
        // Placeholder for adding tutor logic
        setCode('')
    }

    return (
        <div>
            <h2>Add a New Tutor</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Tutor Code:
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </label>
                <button type="submit">Add Tutor</button>
            </form>
        </div>
    )
}

export default AddTutor
