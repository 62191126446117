import React, { useState } from 'react'
/* import styles from './TutorLoginForm.styles' */
import { login } from '../../../features/auth/authActions'
import { useDispatch } from 'react-redux'
import showPassword from '../../../assets/images/showPassword.svg'
import { TutorRegister } from '../../../pages/index.js';
import { Modal } from '../../../components/index.js';
import { Link } from 'react-router-dom'
import config, { apiBaseUrl } from '../../../config'


function TutorLoginForm({ onLoginSuccess, /* onLoginError */ }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [passwordVisible, setPasswordVisible] = useState()
    const [closeModal, setCloseModal] = useState()
    const [openRegist, setOpenRegist] = useState()
    const apiBaseUrl = config.API_BASE_URL

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await fetch(
                `${apiBaseUrl}/tutor/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                },
            )

            const data = await response.json()
            console.log('Json data: ')
            console.log(data) // Log the response data to check for errors
            if (!response.ok) {
                throw new Error(data.message || 'Failed to login.')
            }

            // Assuming the tutor data is correctly located in data.tutor
            const { tutor } = data
            console.log('Tutor data: ')
            console.log(tutor) // Log the tutor data to ensure it's correct
            const tutorId = tutor._id
            console.log(`Tutor id: ${tutorId}`) // Log the tutor ID to ensure it's correct
            if (tutor) {
                const loginData = {
                    role: tutor.role,
                    email: tutor.email,
                    name: tutor.name,
                    id: tutorId,
                }
                console.log(`loginData: ${loginData}`) // Log the login data to ensure it's correct
                // Dispatch login action with the complete user object
                dispatch(login(loginData))
                onLoginSuccess(tutor) // Pass tutor data to success handler
            } else {
                throw new Error('Tutor data is missing from the response.')
            }
        } catch (error) {
            console.error('Login error:', error) // Log the error
            alert(error)
            /* onLoginError(error.message) */
        } finally {
            setLoading(false)
        }
    }

    const goToRegister = () => {
        setCloseModal(!closeModal)
        setOpenRegist(true)
    }

    return (
        <>
            <form onSubmit={handleSubmit} className='text-white'>
                <h2 className='font-lilita sm:text-[50px] mb-[54px] text-4xl'>Welcome back</h2>
                <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@example.com"
                    required
                    className='bg-[#263768] w-full rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 placeholder:text-white font-light mb-4'
                />
                <div className='relative mb-4'>
                    <input
                        type={passwordVisible ? 'text' : 'password'}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                        className='bg-[#263768] w-full rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-4 placeholder:text-[#5C76B4] font-light mb-1 '
                    />
                    <div className={`absolute top-4 p-1 right-4 ${passwordVisible ? 'opacity-40' : ''}`} onClick={() => setPasswordVisible(!passwordVisible)}>
                        <img src={showPassword} alt="show password" />
                    </div>
                </div>
                {/* {error && <p className='pl-4 text-[#F26325] text-sm'>{error}</p>} */}
                {/*         <button type="submit" style={styles.button} disabled={loading}>
                Login
            </button> */}

                <button type="submit" disabled={loading} className='font-lilita md:text-4xl text-xl font-normal bg-main-color py-3 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] mt-2 [text-shadow:0px_2px_2px_#00000040] w-full mb-4'>
                    {loading ? 'Logging In...' : 'Log In'}
                </button>
                <div className='text-lg w-full text-center text-[#5C76B4]'>Don’t have an account? <Link className='text-[#5667FD] font-bold inline-block' onClick={goToRegister} to={'/'}>Sign up</Link>
                </div>
            </form>
            <Modal open={openRegist} noOvals={false} onClose={() => setOpenRegist(false)}>
                <TutorRegister />
            </Modal>
        </>
    )
}

export default TutorLoginForm
