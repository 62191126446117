// GeneralDashboard.js
import React from 'react'
import { useSelector } from 'react-redux'
import StudentDashboard from './StudentDashboard/StudentDashboard'
import TutorDashboard from './TutorDashboard/TutorDashboard'

function GeneralDashboard() {
    const { userRole } = useSelector((state) => state.auth)

    switch (userRole) {
        case 'student':
            return <StudentDashboard />
        case 'tutor':
            return <TutorDashboard />
        default:
            return <div>Loading...</div> // Or any other fallback UI
    }
}

export default GeneralDashboard
