// src/components/Navbar/components/LoggedOutNav/LoggedOutNav.jsx
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
/* import styles from '../../Navbar.styles' */
import ActiveL from '../../../../assets/images/active-l.svg'
import ActiveR from '../../../../assets/images/active-r.svg'
import burgerIcon from '../../../../assets/images/burder.svg'
import closeIcon from '../../../../assets/images/ic_close_white.svg'
import { Modal } from '../../../../components/index.js';
import { TutorRegister } from '../../../../pages/index.js';
import TutorLoginForm  from '../../../../pages/LoginPage/TutorLoginForm/TutorLoginForm.jsx';
import { useNavigate } from 'react-router-dom'

function LoggedOutNav() {
    const [mobMenuOpen, setMobMenuOpen] = useState();
    const [openRegist, setOpenRegist] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState('')
    const [loginType, setLoginType] = useState('tutor')

    const handleRegister = () => {
        setOpenRegist(true)
    }

    const handleLogin = () => {
        setOpenLogin(true)
        /* navigate('/login') */
    }

    const handleLoginSuccess = (userType, userData) => {
        navigate('/dashboard')
    }

    const handleLoginError = (error) => {
        setErrorMessage(error)
        setLoginType('')
    }
console.log(loginType)
console.log(errorMessage)

    return (
        <div className='relative w-full text-end'>
            <div className='visible lg:hidden flex justify-end items-center'>
                {mobMenuOpen && <div onClick={(() => setMobMenuOpen(!mobMenuOpen))} className='fixed inset-0 z-20 flex justify-end text-white bg-[#090830] p-10 transition-colors w-zcreen h-screen'>
                    <div className='mt-6 pt-4'>

                        <ul className='flex flex-col'>
                            <li className='flex justify-end'>
                                <NavLink to="/">
                                    {({ isActive }) => {
                                        return isActive ? (
                                            <div className='mt-3 p-2 w-fit'>
                                                <nav className="text-center text-[#D1C4E9]">Home</nav>
                                                <div className='flex px-1 mt-[9px] justify-between w-full'>
                                                    <img src={ActiveL} alt="active" />
                                                    <img src={ActiveR} alt="active" />
                                                </div>
                                            </div>
                                        ) : (
                                            <nav className="p-2 mt-3">Home</nav>
                                        );
                                    }}
                                </NavLink>
                            </li>
                            <li className='flex justify-end'>
                                <NavLink to="/about" >
                                    {({ isActive }) => {
                                        return isActive ? (
                                            <div className='mt-3 p-2'>
                                                <nav className="text-center text-[#D1C4E9]">About</nav>
                                                <div className='flex px-1 mt-[9px] justify-between w-full'>
                                                    <img src={ActiveL} alt="active" />
                                                    <img src={ActiveR} alt="active" />
                                                </div>
                                            </div>
                                        ) : (
                                            <nav className="mt-3 p-2 ">About</nav>
                                        );
                                    }}
                                </NavLink>
                            </li>
                            <li className='flex justify-end'>
                                <NavLink to="/contact">
                                    {({ isActive }) => {
                                        return isActive ? (
                                            <div className='mt-3 p-2'>
                                                <nav className="text-center text-[#D1C4E9]">Contact</nav>
                                                <div className='flex px-2 mt-[9px] justify-between w-full'>
                                                    <img src={ActiveL} alt="active" />
                                                    <img src={ActiveR} alt="active" />
                                                </div>
                                            </div>
                                        ) : (
                                            <nav className="p-2 mt-3">Contact</nav>
                                        );
                                    }}
                                </NavLink>
                            </li>
                            <li className='flex justify-end'>
                                        <a
                                    href="https://apps.apple.com/ee/app/joyed-student-app/id6514306586"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                                className="p-2 mt-3"
                                            >
                                            Download iOS
                                        </a>
                            </li>
                        </ul>
                        <div className='flex flex-col justify-end mt-3'>
                            <button className='text-base p-2 text-right' onClick={handleLogin}>
                                Tutor login
                            </button>
                            <button to="/account-creation" className='font-lilita text-[18px] font-normal bg-main-color py-2 px-5 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)]  [text-shadow:0_2px_2px_#00000040] mt-3' onClick={handleRegister}
                            >
                                Tutor register
                            </button>
                        </div>



                    </div>
                </div>}
                <button className='p-2' onClick={(() => setMobMenuOpen(!mobMenuOpen))}>
                    {mobMenuOpen ? (<img className='z-20 relative' src={closeIcon} alt="burder menu" />)
                        : (<img className='mr-1' src={burgerIcon} alt="burder menu" />)}
                </button>

            </div>
            <div className='hidden lg:flex justify-between w-full text-white lg:visible'>

                <ul className='flex'>
                    <li>
                        <NavLink to="/">
                            {({ isActive }) => {
                                return isActive ? (
                                    <div className='mr-8 mt-3 p-2'>
                                        <nav className="text-center text-[#D1C4E9]">Home</nav>
                                        <div className='flex px-1 mt-[9px] justify-between w-full'>
                                            <img src={ActiveL} alt="active" />
                                            <img src={ActiveR} alt="active" />
                                        </div>
                                    </div>
                                ) : (
                                    <nav className="p-2 mr-8 mt-3">Home</nav>
                                );
                            }}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" >
                            {({ isActive }) => {
                                return isActive ? (
                                    <div className='mr-8 mt-3 p-2'>
                                        <nav className="text-center text-[#D1C4E9]">About</nav>
                                        <div className='flex px-1 mt-[9px] justify-between w-full'>
                                            <img src={ActiveL} alt="active" />
                                            <img src={ActiveR} alt="active" />
                                        </div>
                                    </div>
                                ) : (
                                    <nav className="mr-8 mt-3 p-2 ">About</nav>
                                );
                            }}
                        </NavLink>
                    </li>
            
                      <li>
                            <a
                                href="https://apps.apple.com/ee/app/joyed-student-app/id6514306586"
                                target="_blank"
                                rel="noopener noreferrer"

                            >
                                <nav className="p-2 mr-8 mt-3">Download iOS</nav>
                            </a>
                    </li>
                </ul>
                <div className='mt-3 flex items-center'>
                <button className='text-base p-1' onClick={handleLogin}>
                                Tutor login
                            </button>
                    <button onClick={handleRegister} className='ml-9 font-lilita text-[18px] font-normal bg-main-color py-2 px-5 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] mr-9 [text-shadow:0_2px_2px_#00000040]'>
                        Tutor register
                    </button>
                </div>
            </div>
            <Modal open={openRegist} noOvals={false} onClose={() => setOpenRegist(false)}>
                <TutorRegister />
            </Modal>
            <Modal open={openLogin} noOvals={false} onClose={() => setOpenLogin(false)}>
                <TutorLoginForm onLoginSuccess={(userData) =>
                        handleLoginSuccess('tutor', userData)
                    }
                    onLoginError={handleLoginError} />
            </Modal>

        </div>
    )
}

export default LoggedOutNav
