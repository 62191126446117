import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import config from '../../../../../config';
import closeIcon from '../../../../../assets/images/ic_close.svg';
import smallSmile from '../../../../../assets/images/smallSmile.png';

function StudentManagement() {
    const [studentIdArray, setStudentIdArray] = useState([]);
    const apiBaseUrl = config.API_BASE_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [dropDownOpen, setDropdownOpen] = useState(null);
    const [studentEmail, setStudentEmail] = useState('');
    const [emailWarning, setEmailWarning] = useState('');
    //const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [selectedHomework, setSelectedHomework] = useState(null);
    const tutor = useSelector((state) => state.auth);
    const tutorId = tutor.userInfo.id;
    const [students, setStudents] = useState();
    const [studentHomework, setStudentHomework] = useState();
    const [flattenStudentHomework, setFlattenStudentHomework] = useState();
    const handleDropdown = (index) => {
        if (dropDownOpen === index) {
            setDropdownOpen(null);
        } else {
            setDropdownOpen(index);
        }
    };

    const getStudentIdByEmail = async (studentEmail) => {
        const response = await fetch(`${apiBaseUrl}/student/${studentEmail}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error('Failed to get student ID:', response.statusText);

            return null;
        }

        const data = await response.text();
        if (data) {
            const parsedData = JSON.parse(data);
            const studentId = parsedData.student._id;
            return studentId;
        }
    };
    const [myStudentbyId, setMyStudentbyId] = useState();
    const getStudentById = useCallback(async (studentId) => {
        const response = await fetch(`${apiBaseUrl}/student/id/${studentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error('Failed to get student:', response.statusText);
            return null;
        }
        const data = await response.json();
        setMyStudentbyId(data)

        if (data) {
            return data.student;
        } else {
            return null;
        }
    }, [apiBaseUrl]);
    console.log(myStudentbyId)
    

    const parseStudentIdArray = useCallback(async (studentIdArray) => {
        const promises = studentIdArray.map((studentId) => getStudentById(studentId));
        return Promise.all(promises);
    }, [getStudentById]);

    const handleAddStudent = useCallback(async (studentName) => {
        const studentId = await getStudentIdByEmail(studentName);

        if (!studentId) {
            console.error(`Student with email ${studentName} not found`);
            return;
        }

        // Check if the student is already added, if so, remove them
        if (studentIdArray.includes(studentName)) {
            handleRemoveStudent(studentName);
            return;
        }

        try {
            const response = await fetch(`${apiBaseUrl}/tutor/add-student/${tutorId}/${studentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tutorId: tutorId,
                    studentId: studentId,
                    timestamp: new Date(),
                }),
            });

            if (!response.ok) {
                if (response.status === 409) {
                    console.error('Student already added to this tutor');
                    alert('Student already added to this tutor');
                    window.location.reload();
                }
                return;
            }

            const newStudent = await getStudentById(studentId);
            setStudentIdArray((prevIds) => [...prevIds, studentId]);
            setStudentEmail('');
            console.log(newStudent)
        } catch (error) {
            console.error('Error:', error);
        }
    });

    const handleRemoveStudent = async (studentName) => {
        try {
            const response = await fetch(`${apiBaseUrl}/tutor/remove-student/${tutorId}/${studentName}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tutorId: tutorId,
                    studentName: studentName,
                    timestamp: new Date(),
                }),
            });

            if (!response.ok) {
                console.error('Failed to remove student:', response.statusText);
                return;
            }

            // Remove the student from the local state
            setStudentIdArray((prevIds) => prevIds.filter(id => id !== studentName));
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchStudentIds = useCallback(async () => {
        setIsLoading(true);
        const response = await fetch(`${apiBaseUrl}/tutor/students/${tutorId}`);

        if (!response.ok) {
            console.error('Failed to fetch students:', response.statusText);
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        console.log(`Student data:`, data)
        setStudentIdArray(data.students);
        setIsLoading(false);
    }, [tutorId, apiBaseUrl]);

    const getHomeworkByStudentId = useCallback(async (studentId) => {
        const response = await fetch(`${apiBaseUrl}/homework/student/${studentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error('Failed to get homework:', response.statusText);
            return null;
        }
        const data = await response.json();
        console.log(`Homework data -- >> :`, data)

        if (data) {
            return data.homework;
        } else {
            return null;
        }
    }, [apiBaseUrl]);

    const parseStudentIdArrayForHomework = useCallback(async (studentIdArray) => {
        const promises = studentIdArray.map((studentId) => getHomeworkByStudentId(studentId));
        return Promise.all(promises);
    }, [getHomeworkByStudentId]);

    const fetchStudents = useCallback(async () => {
        setIsLoading(true);
        const studentData = await parseStudentIdArray(studentIdArray);
        setStudents(studentData)
        console.log(`Students array:`, studentIdArray)
        console.log(`Parsed students:`, studentData)
        setIsLoading(false);
        console.log(studentData)
    }, [parseStudentIdArray, studentIdArray]);

    const fetchStudentHomework = useCallback(async () => {
        setIsLoading(true);
        const studentHomeworkData = await parseStudentIdArrayForHomework(studentIdArray);
        setStudentHomework(studentHomeworkData);
        setIsLoading(false);
        //console.log("fetchStudentHomeWork", studentHomeworkData);
    }, [studentIdArray, apiBaseUrl]);

    useEffect(() => {
        console.log('students has changed:', students);
    }, [students]);

    useEffect(() => {
        fetchStudentIds();
    }, [fetchStudentIds]);

    useEffect(() => {
        if (studentIdArray.length > 0) {
            fetchStudents();
        }
    }, [studentIdArray, fetchStudents]);

    useEffect(() => {
        if (studentIdArray.length > 0) {
            fetchStudentHomework();
        }
    }, [studentIdArray, fetchStudentHomework]);

    useEffect(() => {
        if (studentHomework) {
            setFlattenStudentHomework(studentHomework.flat());
        }
    }, [studentHomework]);

    /* const recentAssignments = [
        {
            student: 'Mari Kalkun',
            homeworks: [
                {
                    title: 'Calculus Homework #5',
                    dueDate: '2024-05-03',
                    status: 'Completed',
                    practiceTime: '01:10:11',
                    accurancy: '50%',
                    firstOpened: 'Tue 19th 10:11 PM',
                },
                {
                    title: 'Calculus Homework #6',
                    dueDate: '2024-05-06',
                    practiceTime: '01:10:11',
                    status: 'Started',
                    accurancy: '50%',
                    firstOpened: 'Tue 26th 22:11 PM',
                },
                {
                    title: 'Calculus Homework #6',
                    dueDate: '2024-05-06',
                    practiceTime: '01:10:11',
                    status: 'Started',
                    accurancy: '50%',
                    firstOpened: 'Tue 26th 10:11 PM',
                }
            ]
        },
        {
            student: 'Ants Tamm',
            homeworks: [
                {
                    title: 'Quantum Physics Intro',
                    dueDate: '2024-05-04',
                    practiceTime: '01:10:11',
                    status: 'Started',
                    accurancy: '50%',
                    firstOpened: 'Tue 26th 10:11 PM',
                },
                {
                    title: 'Physics Intro',
                    dueDate: '2024-05-02',
                    practiceTime: '01:10:11',
                    status: 'Completed',
                    accurancy: '50%',
                    firstOpened: 'Fri 23th 10:51 PM',
                },
                {
                    title: 'Calculus Homework #6',
                    dueDate: '2024-05-06',
                    practiceTime: '01:10:11',
                    status: 'Started',
                    accurancy: '50%',
                    firstOpened: 'We 20th 17:11 PM',
                }
            ]
        },
    ]; */

    const handleModal = (assignment, homework) => {
        console.log('assignment:', assignment);
        console.log('homework:', homework);
        //setSelectedAssignment(assignment);
        setSelectedHomework(homework);
        setOpen(true);
    }


    useEffect(() => {
        getStudentById(getStudentById())
        console.log('getting student by id')
    }, [getStudentById])

    const formatDate = (dateString) => {
        if (!dateString) return 'Deadline not assigned!';

        const date = new Date(dateString);
        return isNaN(date.getTime()) ? "Invalid date" : date.toISOString().split('T')[0];
    };

    return (
        <div className='sm:px-10 px-5 py-5  pt-[110px] sm:pt-[110px]  md:pt-8'>
            <h1 className='font-lilita md:text-[48px] text-4xl mb-10'>Students</h1>
            <div className='flex flex-wrap items-center'>
                <input
                    type="text"
                    placeholder="email@email.com"
                    name="studentEmail"
                    value={studentEmail}
                    onChange={(e) => {
                        setStudentEmail(e.target.value);
                        if (!/\S+@\S+\.\S+/.test(e.target.value)) {
                            setEmailWarning('Please enter a valid email address');
                        } else {
                            setEmailWarning('');
                        }
                    }}
                    className='bg-[#263768] rounded-lg drop-shadow-[0_6px_7px_0_rgba(0,0,0,0.03)] px-4 py-2 md:py-4 placeholder:text-gray font-light mr-4 mb-3 sm:mb-0'
                />
                
                
                <button
                    onClick={() => handleAddStudent(studentEmail)} // Ensure studentEmail is passed
                    className='font-lilita md:text-4xl text-xl font-normal bg-main-color py-2 px-6 rounded-xl drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] [text-shadow:0px_2px_2px_#00000040] mb-3 sm:mb-0'
                    disabled={isLoading} // Disable button while loading
                >
                    Add
                </button>           
            </div>
            {emailWarning && <p>{emailWarning}</p>}

            {isLoading ? (
                <p>Loading...</p>
            ) : students &&
                students.length > 0 &&
                students.every((student) => student !== null) ? (
                <div>
                    <section className='mt-10 flex justify-center gap-6 flex-wrap sm:justify-between '>
                        {students.map((student) => (
                            <div key={student._id} className=' bg-[#090830] rounded-[20px] p-7 max-w-[508px] min-w-80'>
                                <div className='flex justify-between mb-9 relative'>
                                    <h2 className='font-lilita text-[28px] md:text-[48px]'>{student.name}</h2>
                                    <button className='flex p-1 mt-3 relative' onClick={() => handleDropdown(student._id)}>
                                        <span className='w-1 h-1 mr-1 bg-[#59588D] rounded-full'></span>
                                        <span className='w-1 h-1 mr-1 bg-[#59588D] rounded-full'></span>
                                        <span className='w-1 h-1 bg-[#59588D] rounded-full'></span>
                                    </button>
                                    {
                                        dropDownOpen === student._id && (
                                            <button className={`absolute right-0 top-10 text-base border-[#383570] rounded-xl bg-[#1B193F] border px-4 py-2 ${dropDownOpen ? 'visible' : 'hidden'}`}>
                                                <p onClick={() => handleRemoveStudent(student._id)}>Remove</p>
                                            </button>
                                        )}
                                </div>

                                {Array.isArray(flattenStudentHomework) && flattenStudentHomework.length > 0 ? (
                                    flattenStudentHomework.filter(assignment => assignment.studentId === student._id).map((assignment) => (
                                    <>
                                        <div key={assignment._id} className='flex mb-3 justify-between items-center'>
                                            <div className="mr-10">
                                                <h4 className="md:text-2xl text-base mb-1 md:mb-4">{assignment.title}</h4>
                                                <p className="text-[#59588D] text-xs md:text-base">{formatDate(assignment.deadline)}</p>
                                            </div>
                                            <div
                                                className='flex flex-col sm:flex-row h-fit sm:justify-end sm:items-center items-end'>
                                        <p className={`md:text-lg text-sm mr-0 mb-3 sm:mb-0 sm:mr-[20px] rounded-full bg-opacity-20 py-1 px-3 ${assignment.finished ===true ? 'bg-[#50E3C2] text-[#50E3C2]' : 'bg-[#D7911D] text-[#D7911D]'}`}>Not Finished</p>
                                        {/* <button className='flex p-1' onClick={() => handleModal(assignment, assignment)}>
                                            <span className='w-1 h-1 mr-1 bg-[#59588D] rounded-full'></span>
                                            <span className='w-1 h-1 mr-1 bg-[#59588D] rounded-full'></span>
                                            <span className='w-1 h-1 bg-[#59588D] rounded-full'></span>
                                        </button> */}
                                        <div className={`fixed inset-0 z-30 flex justify-center items-center transition-colors h-screen ${open ? 'visible bg-black/10 backdrop-blur-sm ' : 'invisible'}`} onClick={() => setOpen(false)}>
                                            <div className={`transition-all max-w-[600px] min-w-[500px] rounded-[20px] bg-[#190E49] ${open ? 'scale-100 opacity-100' : 'opacity-0'}`} onClick={(e) => e.stopPropagation()}>
                                                <button className='absolute top-4 right-4 py-1 px-2 rounded-full w-[36px] h-[36px] pointer-events-auto bg-[#5667FD] bg-opacity-25 z-20' onClick={() => setOpen(false)}>
                                                    <img width={24} height={24} src={closeIcon} alt="close" />
                                                </button>


                                                {selectedHomework && (
                                                    <div className='z-10 relative w-full px-14 py-8'>
                                                        <p className='text-[#59588D] text-lg mb-6'>{student.name}</p>
                                                        <p className='text-2xl mb-[42px]'>{selectedHomework.title}</p>
                                                        <div className='flex justify-between'>
                                                            <div className='flex flex-col'>
                                                                <div className='mb-9'>
                                                                    <p className='font-lilita text-[32px] text-[#5667FD]'>01:10:11</p>
                                                                    <p className='text-lg'>Skill Total Practice time</p>
                                                                </div>
                                                                <div>
                                                                    <p className='font-lilita text-[32px] text-[#5667FD]'>{formatDate(assignment.deadline)}</p>
                                                                    <p className='text-lg'>First opened</p>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-col'>
                                                                <div className='mb-9'>
                                                                    <p className='font-lilita text-[32px] text-[#5667FD]'>50%</p>
                                                                    <p className='text-lg'>Accuracy</p>
                                                                </div>
                                                                <div>
                                                                    <img src={smallSmile} alt="raiting" />
                                                                    <p className='text-lg'>Emotional rating</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                        </div>

                                    </>
                                    ))
                                ) : (
                                    <p>No homework assignments available.</p>
                                ) }


                                {/* <div>
                                <strong>{student.name}</strong> ({student.email}
                                )
                            </div>
                            <button
                                onClick={() => handleRemoveStudent(student._id)}
                            >
                                Remove
                            </button> */}
                            </div>
                        ))}

                    </section>
                </div>
            ) : (
                <p className='mt-6'>No students found or there is an error in fetching data</p>
            )}

        </div>
    );
}

export default StudentManagement;
