import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({
    redirectIfAuthenticated = false,
    redirectTo = '/',
}) => {
    const { isLoggedIn } = useSelector((state) => state.auth)

    if (redirectIfAuthenticated && isLoggedIn) {
        return <Navigate to={redirectTo} replace />
    }

    if (!redirectIfAuthenticated && !isLoggedIn) {
        return <Navigate to="/login" replace />
    }

    return <Outlet />
}

export default ProtectedRoute
