import React from 'react'
import ovalHow1 from '../../../../assets/images/oval-how1.svg'
import ovalHow2 from '../../../../assets/images/oval-how2.svg'
import ovalHow3 from '../../../../assets/images/oval-how3.svg'
import magicWand from '../../../../assets/images/magic-wand.svg'
import studyLog from '../../../../assets/images/study-logs.svg'
import smile from '../../../../assets/images/smile2.png'

function HowItWorks() {


    return (
        <section className='mt-[88px] relative'>
            <h1 className='font-lilita font-normal md:text-7xl text-3xl text-main-color text-center md:mb-[72px] mb-10 uppercase'>Benefits for tutor</h1>
            <div className='flex flex-wrap md:flex-nowrap justify-between gap-[42px] min-h-[310px] '>
                <div className='text-center min-h-[259px] md:w-[286px] px-[19px] flex w-full justify-around items-center flex-col relative'>
                    <div className='absolute top-0'>
                        <img src={magicWand} alt="Magic wand" />
                    </div>
                    <div className='px-4 pt-12 pb-6 rounded-3xl'>
                        <img className='rounded-[15px] w-48 sm:w-full' src={ovalHow1} alt="background-oval" />
                    </div>
                    <div className='max-w-[286px]'>
                        <h3 className='font-lilita font-normal text-[28px] mb-[14px]'>AI generated tasks</h3>
                        <p className='font-normal'>AI-powered practice tool to generate customized tasks to students' knowledge and interests</p>
                    </div>
                </div>
                <div className='w-fit lg:flex items-center hidden '>
                    <div className='bg-[#311B92] w-3 h-3 rounded-full mr-3'></div>
                    <div className='bg-[#5F3CA1] h-3 w-[72px] rounded-full'></div>
                </div>
                <div className='text-center min-h-[259px] md:w-[286px] w-full px-2 flex justify-around items-center flex-col relative pt-4'>
                    <div className='absolute top-0'>
                        <img className='p-2' src={studyLog} alt="Study log" />
                    </div>
                    <div className='px-4 pt-7 pb-6  rounded-3xl'>
                        <img className='rounded-[15px] w-48 sm:w-full' src={ovalHow2} alt="background-oval" />
                    </div>
                    <div className='max-w-[286px]'>
                        <h3 className='font-lilita text-[28px] mb-[14px]'>Study logs</h3>
                        <p className='font-normal'>View study logs that detail accuracy, shortcomings and time spent on learning</p>
                    </div>

                </div>
                <div className='w-fit lg:flex items-center hidden'>
                    <div className='bg-[#311B92] w-3 h-3 rounded-full mr-3'></div>
                    <div className='bg-[#5F3CA1] h-3 w-[72px] rounded-full'></div>
                </div>
                <div className='text-center min-h-[259px] md:w-[286px] w-full px-2 flex justify-around items-center flex-col relative pt-4'>
                    <div className='absolute top-0'>
                        <img className='p-2' src={smile} alt="Smile" />
                    </div>
                    <div className='px-4 pt-8 pb-6 rounded-3xl'>
                        <img className='rounded-[15px] w-48 sm:w-full' src={ovalHow3} alt="background-oval" />
                    </div>
                    <div className='max-w-[286px]'>
                        <h3 className='font-lilita text-[28px] mb-[14px]'>Student Feedback</h3>
                        <p className='font-normal'>Study sessions are rated by students, providing feedback to tutors and AI on how to create better content</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorks
