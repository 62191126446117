import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './Homework.styles'
import config from '../../../../../config'
import TaskModal from './TaskModal'

function Homework() {
    const [tutorAssignments, setTutorAssignments] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [generatedTask, setGeneratedTask] = useState(null)
    const apiBaseUrl = config.API_BASE_URL
    const studentId = useSelector((state) => state.auth.userInfo.id)

    useEffect(() => {
        const fetchAssignments = async () => {
            try {
                console.log('Fetching assignments for student:', studentId)
                const response = await fetch(`${apiBaseUrl}/homework/student/${studentId}`)
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const fetchedAssignments = await response.json()
                setTutorAssignments(fetchedAssignments.homework)
            } catch (error) {
                console.error('Error fetching assignments:', error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchAssignments()
    }, [apiBaseUrl, studentId])

    const handleGenerateTask = async (homeworkId) => {
        try {
            const response = await fetch(`${apiBaseUrl}/tasks/generate/${studentId}/${homeworkId}`, {
                method: 'POST'
            })
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            const taskData = await response.json()
            setGeneratedTask(taskData.task)
            setIsModalOpen(true)
        } catch (error) {
            console.error('Error generating task:', error)
        }
    }

    return (
        <div style={styles.homeworkContainer}>
            <h2>Tutor Assignments</h2>
            {isLoading ? (
                <p>Loading...</p>
            ) : tutorAssignments.length ? (
                <ul style={styles.homeworkList}>
                    {tutorAssignments.map((assignment) => (
                        <li key={assignment.id + assignment.title}>
                            <h3>{assignment.title}</h3>
                            <p>{assignment.description}</p>
                            <p>Due: {new Date(assignment.deadline).toLocaleDateString()}</p>
                            <button
                                style={styles.viewAllButton}
                                onClick={() => handleGenerateTask(assignment._id)}
                            >
                                Get a task
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No assignments found</p>
            )}

            <TaskModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                task={generatedTask}
            />
        </div>
    )
}

export default Homework
