import React, { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './calendar-styles.css'
import taskListData from './CalendarTempData'
import { useNavigate } from 'react-router-dom'

// Sample data structure

function CalendarView() {
    const [value, setValue] = useState(new Date())
    const navigate = useNavigate() // eslint-disable-line no-unused-vars

    const onChange = (newValue) => {
        setValue(newValue)
    }

    // Function to parse task dates and check for tasks on each date
    const findTasksForDate = (date) => {
        const tasksOnDate = []
        taskListData['Weekly Tasks'].forEach((week) => {
            week.Tasks.forEach((task) => {
                const taskDueDate = new Date(task.Due)
                if (taskDueDate.toDateString() === date.toDateString()) {
                    tasksOnDate.push(task.Description)
                }
            })
        })
        return tasksOnDate
    }

    // Render tasks as content on each tile if there are any tasks due on that date
    const tileContent = ({ date, view }) => {
        // Only add content to 'month' view tiles
        if (view === 'month') {
            const tasks = findTasksForDate(date)
            if (tasks.length > 0) {
                return (
                    <ul style={{ padding: 0, margin: 0 }}>
                        {tasks.map((task, index) => (
                            <li key={index} style={{ listStyleType: 'none' }}>
                                {task}
                            </li>
                        ))}
                    </ul>
                )
            }
        }
    }

    return (
        <div>
            <h2>Your Personalized Study Plan:</h2>
            <Calendar
                style={{ width: '800px' }}
                onChange={onChange}
                value={value}
                tileContent={tileContent}
            />
            <p>Selected Date: {value.toDateString()}</p>
        </div>
    )
}

export default CalendarView
