const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: 'auto',
        textAlign: 'center',
    },
    button: {
        padding: '10px 20px',
        margin: '0 10px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#5667fd',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        transition: 'background-color 0.2s',
    },
    buttonActive: {
        backgroundColor: '#0056b3',
        color: '#ffffff',
    },
    heading: {
        margin: '20px 0',
        color: '#333',
    },
    nav: {
        marginBottom: '30px',
    },
}

export default styles
