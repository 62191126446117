// src/components/Navbar/Navbar.jsx
import React from 'react'
import { Link } from 'react-router-dom'
/* import styles from './Navbar.styles' // Ensure path accuracy */
import LoggedInNav from './components/LoggedInNav/LoggedInNav'
import LoggedOutNav from './components/LoggedOutNav/LoggedOutNav'
import { useSelector } from 'react-redux'
import JoyedLogo from '../../assets/images/logo-landing.png'

function Navbar() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    return (
        <nav className='pb-4 h-[100px] relative z-30 '>
            <div className={`py-[21px]  ${isLoggedIn ? ' flex justify-end items-center ml-8 relative w-full' : 'flex items-center'}`}>
                <Link to="/" className={` ${isLoggedIn ? 'hidden' : ''}`} >
                    <img className='' src={JoyedLogo} alt="Joyed logo" />
                </Link>
                {isLoggedIn ? <LoggedInNav /> : <LoggedOutNav />}
            </div>
        </nav>
    )
}

export default Navbar
