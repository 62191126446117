import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
/* import styles from '../../Navbar.styles' */
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../../features/auth/authActions'
import arrow from '../../../../assets/images/arrow_down.svg'
import JoyedLogo from '../../../../assets/images/logo-landing.png'
import closeIcon from '../../../../assets/images/ic_close_white.svg'
import burgerIcon from '../../../../assets/images/burder.svg'
import activeMark from '../../../../assets/images/activeMark.svg'
import dashboardIconActive from '../../../../assets/images/dashboardIconActive.svg'
import studentIconActive from '../../../../assets/images/studentIconActive.svg'
import homeworkIconActive from '../../../../assets/images/homeworkIconActive.svg'
import dashboardIcon from '../../../../assets/images/dashboardIcon.svg'
import studentIcon from '../../../../assets/images/studentIcon.svg'
import homeworkIcon from '../../../../assets/images/homeworkIcon.svg'

function LoggedInNav() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userInfo = useSelector((state) => state.auth.userInfo)
    const [dropDownOpen, setDropdownOpen] = useState(false);
    const [mobMenuOpen, setMobMenuOpen] = useState();

    const links = [
        { path: 'students', name: 'Students' },
        { path: 'homework', name: 'Homework' },
    ]

    const handleLogout = () => {
        if (window.confirm('Are you sure you want to log out?')) {
            dispatch(logout())
            navigate('/')
        }
    }
    const handleDropdown = () => setDropdownOpen(!dropDownOpen);

    return (
        <>
            <div className='visible md:hidden flex bg-[#090830] w-screen absolute top-0 -mt-6 -ml-8 x '>
                <div className='sm:px-10 mt-6 px-4 py-3 w-full flex justify-between items-center '>

                    <Link to="/">
                        <img className='w-[140px] sm:w-[200px]' src={JoyedLogo} alt="Joyed logo" />
                    </Link>
                    <div className='flex justify-between sm:w-40 items-center relative z-50'>
                        <div className='font-lilita relative text-[20px] sm:text-[28px] font-normal bg-main-color w-10 h-10 sm:w-12 sm:h-12 flex justify-center items-center rounded-full drop-shadow-[0_14px_32px_-2px_rgba(0,0,0,0.1)] shadow-[inset_2px_2px_2px_0_rgb(130,142,255)] [text-shadow:0px_2px_2px_#00000040] mr-3 text-white '><span className='first-letter:text-[28px] text-[0px]'>{userInfo?.name}</span></div>
                        {mobMenuOpen && <div onClick={(() => setMobMenuOpen(!mobMenuOpen))} className='fixed top-[76px] z-50 flex justify-start text-white bg-[#090830] py-10 transition-colors w-screen h-screen left-0'>
                            <nav>
                                <ul className='list-none mt-4'>
                                    <li className='mb-[10px] py-2'>
                                        <NavLink to="/dashboard" end>
                                            {({ isActive }) => {
                                                return isActive ? (
                                                    <div className='flex items-center relative'>
                                                        <img className='absolute left-0' src={activeMark} alt="active" />
                                                        <img className='mr-[18px] ml-[46px]' src={dashboardIconActive} alt="dashboard active" />
                                                        <nav className="text-center text-[#53B9EA] flex">Dashboard</nav>
                                                    </div>
                                                ) : (
                                                    <div className='flex items-center'>
                                                        <img className='mr-[18px] ml-[46px]' src={dashboardIcon} alt="dashboard active" />
                                                        <nav className="text-center  text-[#6F6C99] flex">Dashboard</nav>
                                                    </div>
                                                );
                                            }}
                                        </NavLink>
                                    </li>
                                    {links.map((link) => (
                                        <li key={link.path} className='mb-[10px] py-2'>
                                            <NavLink to={`/dashboard/${link.path}`}>
                                                {({ isActive }) => {
                                                    return isActive ? (
                                                        <div className='flex items-center relative'>
                                                            <img className='absolute left-0' src={activeMark} alt="active" />
                                                            <img className='mr-[18px] ml-[46px]' src={link.name === 'Students' ? studentIconActive : homeworkIconActive} alt={link.name + 'active'} />
                                                            <nav className="text-center text-[#53B9EA] flex">{link.name}</nav>
                                                        </div>
                                                    ) : (
                                                        <div className='flex items-center'>
                                                            <img className='mr-[18px] ml-[46px]' src={link.name === 'Students' ? studentIcon : homeworkIcon} alt={link.name + 'not active'} />
                                                            <nav className="text-center text-[#6F6C99] flex">{link.name}</nav>
                                                        </div>
                                                    );
                                                }}
                                            </NavLink>
                                        </li>
                                    ))}
                                    <li className='px-4 py-2 ml-6 rounded-lg bg-[#090830] text-[#5B5A99]'>
                                        <button className='py-1 px-2' onClick={handleLogout} >
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>}
                        <button className='p-2' onClick={(() => setMobMenuOpen(!mobMenuOpen))}>
                            {mobMenuOpen ? (<img className='z-20 relative' src={closeIcon} alt="burder menu" />)
                                : (<img className='mr-1' src={burgerIcon} alt="burder menu" />)}
                        </button>
                    </div>
                </div>
            </div>

            <ul className=' items-center hidden md:flex '>
                {/* <li>
                    <NavLink to="/dashboard" style={styles.link}>
                        Dashboard
                    </NavLink>
                </li> */}
                <li className='flex relative'>
                    <button onClick={handleDropdown} className='text-[#5B5A99] flex items-center font-normal text-2xl mr-4 p-1'>
                        <p className='mr-4'> Hi, {userInfo?.name}!</p>
                        <img className={dropDownOpen ? 'rotate-180' : ''} src={arrow} alt="dropdown" />
                    </button>
                    {dropDownOpen &&
                        <div className='absolute right-4 top-10 px-4 py-2 rounded-lg bg-[#090830] text-[#5B5A99]'>
                            <button className='py-1 px-2' onClick={handleLogout} >
                                Logout
                            </button>
                        </div>
                    }
                </li>
            </ul>
        </>
    )
}

export default LoggedInNav
