// HomePage.jsx
import React from 'react'
import HeroSection from './components/HeroSection/HeroSection'
/* import Features from './components/Features/Features' */
/* import Testimonials from './components/Testimonials/Testimonials' */
/* import CallToAction from './components/CallToAction/CallToAction' */
import HowItWorks from './components/HowItWorks/HowItWorks'
import HowStudentsLearn from './components/HowStudentsLearn/HowStudentsLearn'

const LandingPage = () => (
    <div className=' text-white'>
        <HeroSection />
        <HowItWorks />
        <HowStudentsLearn />
        {/* <Features /> */}
        {/* <Testimonials /> */}
        {/* <CallToAction /> */}
    </div>
)

export default LandingPage
