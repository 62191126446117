import React, { useState } from 'react'
import styles from './CreateGoal.styles'
import config from '../../../../../../../config'
import { useSelector } from 'react-redux'

function CreateGoal() {
    const baseUrl = config.API_BASE_URL
    const [loading, setLoading] = useState(false)
    // get student ID from redux
    const studentId = useSelector((state) => state.auth.userInfo.id)
    const [goal, setGoal] = useState({
        title: '',
        description: '',
        // set default deadline to 7 days from today
        deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setGoal((prevGoal) => ({
            ...prevGoal,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()
        console.log('Sending request to URL:', `${baseUrl}/goals`)
        console.log(
            'Request body:',
            JSON.stringify({
                ...goal,
                student: studentId,
            }),
        )

        try {
            const response = await fetch(`${baseUrl}/goals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...goal,
                    student: studentId,
                }),
            })

            if (!response.ok) {
                console.error('Failed to create goal:', response.statusText)
                alert(`Failed to create goal: ${response.statusText}`)
                setLoading(false)
                return
            }

            const data = await response.json()
            console.log('Goal created:', data)
            setGoal({
                title: '',
                description: '',
                deadline: '',
            })

            alert('Goal created successfully')
        } catch (error) {
            console.error('Error creating goal:', error)
            alert('Failed to create goal')
        }
        setLoading(false)
    }

    return (
        <div>
            <h2>Create a New Goal</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.div}>
                    <label style={styles.label}>
                        Goal Title:
                        <input
                            type="text"
                            name="title"
                            value={goal.title}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </label>
                </div>
                <div style={styles.div}>
                    <label style={styles.label}>
                        Description:
                        <textarea
                            name="description"
                            value={goal.description}
                            onChange={handleChange}
                            style={{ ...styles.input, ...styles.textarea }}
                        />
                    </label>
                </div>
                <div style={styles.div}>
                    <label style={styles.label}>
                        Deadline:
                        <input
                            type="date"
                            name="deadline"
                            value={goal.deadline}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </label>
                </div>
                <button type="submit" style={styles.button} disabled={loading}>
                    Submit Goal
                </button>
            </form>
        </div>
    )
}

export default CreateGoal
