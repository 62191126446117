// TutorHomework.jsx.styles.js
const styles = {
    homeworkContainer: {
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
    },
    listItem: {
        marginBottom: '10px',
    },
    // Add more styles as needed
}

export default styles
