import React from 'react'
import LinkedIn from '../../assets/images/linkedin.svg'
import JoyedLogo from '../../assets/images/logo-landing.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Footer() {

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    return (
        <footer className={`text-white text-base absolute bottom-0 flex flex-col left-0 right-0 m-auto mx-8 xl:mx-auto max-w-[1280px] ${isLoggedIn ? 'hidden' : ''}`}>
            <div className='flex justify-between w-full items-center mb-9'>
                <div className=''>
                    <img src={JoyedLogo} alt="Logo" />
                </div>
                <ul className='flex flex-col sm:flex-row gap-1 -mb-[70px] sm:mb-0 text-endjustify-betwee sm:items-center items-end'>
                    <li>
                        <Link to="/" className='p-1 sm:mr-7 mr-0'>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className='p-1 sm:mr-7 mr-0 text-end' to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className='p-1 sm:mr-7 mr-0' to="/contact">
                            Contact
                        </Link>
                    </li>
                    <li>
                        <a className='p-1' href="https://www.linkedin.com/company/joyed-ai/">
                            <img src={LinkedIn} alt="LinkedIn" />
                        </a>
                    </li>
                </ul>
            </div>
            <div className='flex leading-[24px] flex-wrap justify-between pb-12 w-full'>
                <p className='hidden sm:flex flex-wrap pr-28 mb-3 sm:mt-0'>Creating pathways to educational success through innovation and technology.</p>
                <p className='w-40 sm:w-fit'>© {new Date().getFullYear()} Joyed OÜ. All rights
                reserved.</p>
            </div>
        </footer>
    )
}

export default Footer
