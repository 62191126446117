// features/auth/authActions.js

export const actionTypes = {
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    SET_ROLE: 'auth/setRole',
}

export const login = (user) => {
    console.log('Dispatching Login Action:', user)
    return {
        type: actionTypes.LOGIN,
        payload: user,
    }
}

export const logout = () => ({
    type: actionTypes.LOGOUT,
})

export const setRole = (role) => ({
    type: actionTypes.SET_ROLE,
    payload: { role },
})
