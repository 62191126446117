// AccessCodeEntry.styles.js
import colors from '../../../styles/colors'

const styles = {
    entryContainer: {
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        textAlign: 'center',
    },
    input: {
        marginBottom: '10px',
        padding: '10px',
        fontSize: '16px',
    },
    button: {
        padding: '10px',
        backgroundColor: colors.primary,
        color: colors.background,
        border: 'none',
        cursor: 'pointer',
    },
}

export default styles
