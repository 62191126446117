import React, { useEffect, useRef, useState } from 'react'
import styles from './TaskModal.styles'
import Latex from 'react-latex-next'

const TaskModal = ({ isOpen, onClose, task }) => {
    const [selectedOption, setSelectedOption] = useState(null)
    const modalRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose()
            }
        }

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen, onClose])

    if (!isOpen) return null

    const handleOptionClick = (index) => {
        setSelectedOption(index)
    }

    return (
        <div style={styles.modalOverlay}>
            <div ref={modalRef} style={styles.modalContent}>
                <h3>Generated Task</h3>
                {task ? (
                    <div>
                        <Latex>{task.question}</Latex>
                        <ul style={styles.optionList}>
                            {task.options.map((option, index) => (
                                <li
                                    key={index}
                                    style={{
                                        ...styles.optionItem,
                                        backgroundColor: selectedOption === index ? '#d3d3d3' : '#fff'
                                    }}
                                    onClick={() => handleOptionClick(index)}
                                >
                                    <Latex>{`${index + 1}. ${option}`}</Latex>
                                </li>
                            ))}
                        </ul>
                        {selectedOption !== null && (
                            <p style={styles.correctOption}>
                                <strong>Correct Option:</strong> {task.correct_option}
                            </p>
                        )}
                    </div>
                ) : (
                    <p>No task generated yet.</p>
                )}
                <button onClick={onClose} style={styles.button}>Close</button>
            </div>
        </div>
    )
}

export default TaskModal
