import React, { useState, useEffect } from 'react'
import config from '../../../../../../../config'
import { useSelector } from 'react-redux'
import GoalsList from './components/GoalsList' // Make sure to import the new component

const CurrentGoals = () => {
    const baseUrl = config.API_BASE_URL
    const [goals, setGoals] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const studentId = useSelector((state) => state.auth.userInfo.id)

    useEffect(() => {
        const fetchGoals = async () => {
            setLoading(true)
            try {
                const response = await fetch(
                    `${baseUrl}/goals/student/${studentId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                if (!response.ok) {
                    throw new Error('Failed to fetch goals')
                }
                const data = await response.json()
                setGoals(data.goals) // Assuming the API returns an object with a goals array
            } catch (error) {
                console.error('Error fetching goals:', error)
                setError(error.message)
            }
            setLoading(false)
        }

        fetchGoals()
    }, [baseUrl, studentId])

    return <GoalsList goals={goals} loading={loading} error={error} />
}

export default CurrentGoals
