// /pages/index.js
export { default as LandingPage } from './LandingPage/LandingPage'
export { default as AccessCodeEntry } from './StudentSignUpPage/AccessCodeEntry/AccessCodeEntry'
export { default as TrigonometryProblems } from './TrigonometryProblems/TrigonometryProblems'
export { default as StudentLandingPage } from './StudentSignUpPage/StudentSignUpPage'
export { default as AccountCreation } from './StudentSignUpPage/AccountCreation/AccountCreation'
export { default as TutorRegister } from './TutorRegister/TutorRegister'

export { default as Dashboard } from './Dashboard/Dashboard'
export { default as LoginPage } from './LoginPage/LoginPage'

export {default as AboutPage } from './AboutPage/AboutPage'
export {default as ContactPage } from './ContactPage/ContactPage'
export {default as PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy'
