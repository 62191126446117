import React, { useState } from 'react'
import config from '../../../../../../../../config'
import EvaluationModal from './EvaluationModal'
import { useSelector } from 'react-redux'

const apiBaseUrl = config.API_BASE_URL

function GoalsList({ goals, loading, error }) {
    const [apiLoading, setApiLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [evaluationData, setEvaluationData] = useState(null)
    const studentId = useSelector((state) => state.auth.userInfo.id)
    // Define styles
    const containerStyle = {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    }

    const headerStyle = {
        color: '#333',
        borderBottom: '2px solid #666',
    }

    const errorStyle = {
        color: 'red',
        fontWeight: 'bold',
    }

    const listStyle = {
        listStyleType: 'none',
        paddingLeft: '0',
    }

    const listItemStyle = {
        background: '#f8f8f8',
        border: '1px solid #ddd',
        padding: '10px',
        margin: '5px 0',
    }

    const descriptionStyle = {
        fontStyle: 'italic',
        fontSize: '14px',
    }

    const deadlineStyle = {
        color: 'blue',
    }

    const generateEvaluation = async (goalId) => {
        setApiLoading(true)
        try {
            // "/api/v1/evaluation-test/:goalId",
            const response = await fetch(
                `${apiBaseUrl}/evaluation-test/${goalId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ goalId }),
                },
            )

            if (!response.ok) {
                console.log(
                    'Failed to generate evaluation:',
                    response.statusText,
                )
                console.log('Failed to generate evaluation:', response)
                throw new Error(
                    `Failed to generate evaluation for goal ${goalId}`,
                )
            }

            alert('Evaluation generated successfully')
        } catch (error) {
            console.error('Error generating evaluation:', error)
            alert('Failed to generate evaluation')
        }
        setApiLoading(false)
    }

    const fetchEvaluation = async (goalId) => {
        setApiLoading(true)
        try {
            const response = await fetch(
                `${apiBaseUrl}/evaluation-test/${studentId}/${goalId}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                },
            )
            if (!response.ok) {
                throw new Error(`Failed to fetch evaluation for goal ${goalId}`)
            }
            const data = await response.json()
            setEvaluationData(data.evaluationTest)
            setShowModal(true)
        } catch (error) {
            console.error('Error fetching evaluation:', error)
            alert('Failed to fetch evaluation')
        }
        setApiLoading(false)
    }

    return (
        <div style={containerStyle}>
            <h2 style={headerStyle}>Your Current Goals</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p style={errorStyle}>Error: {error}</p>
            ) : (
                <ul style={listStyle}>
                    {goals.length > 0 ? (
                        goals.map((goal, index) => (
                            <li key={index} style={listItemStyle}>
                                <strong>{goal.title}</strong>
                                <br />
                                <span style={descriptionStyle}>
                                    {goal.description}
                                </span>
                                <br />
                                <span style={deadlineStyle}>
                                    Deadline:{' '}
                                    {new Date(
                                        goal.deadline,
                                    ).toLocaleDateString()}
                                </span>
                                <button
                                    onClick={() => generateEvaluation(goal._id)}
                                    disabled={apiLoading}
                                >
                                    Generate evaluation
                                </button>
                                <button
                                    onClick={() => fetchEvaluation(goal._id)}
                                    disabled={apiLoading}
                                >
                                    View evaluation
                                </button>
                            </li>
                        ))
                    ) : (
                        <p>You have no current goals.</p>
                    )}
                </ul>
            )}
            {showModal && (
                <EvaluationModal
                    evaluationData={evaluationData}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    )
}

export default GoalsList
