// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: default;
}


.text-shadow {
    text-shadow: 0px 2px 2px 0px #00000040
}
/* #root, */
/* .App { */
/*     display: flex;
    flex-direction: column;
    min-height: 100vh;  */
    /* Use viewport height to ensure the .App container can stretch */
/* } */

/* .bodyContainer {
    flex: 1; */
     /* This allows the container to expand and fill available space */
/*     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
     /* Adjust this to flex-start to align content to the top */
/*     max-width: 1200px;
    width: 100%;
    padding: 0;
    margin: 0 auto;  */
    /* Center the content horizontally */
/* } */

/* Add styles for the footer to ensure it doesn't collapse */
/* footer {
    width: 100%; */
     /* Ensure the footer stretches across the bottom */
    /* Add additional styling here for padding, background, etc. */
/* }
 */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,SAAS;IACT,UAAU;IACV,eAAe;AACnB;;;AAGA;IACI;AACJ;AACA,WAAW;AACX,WAAW;AACX;;yBAEyB;IACrB,iEAAiE;AACrE,MAAM;;AAEN;cACc;KACT,iEAAiE;AACtE;;;kCAGkC;KAC7B,0DAA0D;AAC/D;;;sBAGsB;IAClB,oCAAoC;AACxC,MAAM;;AAEN,4DAA4D;AAC5D;kBACkB;KACb,kDAAkD;IACnD,8DAA8D;AAClE;EACE","sourcesContent":["html,\nbody {\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    cursor: default;\n}\n\n\n.text-shadow {\n    text-shadow: 0px 2px 2px 0px #00000040\n}\n/* #root, */\n/* .App { */\n/*     display: flex;\n    flex-direction: column;\n    min-height: 100vh;  */\n    /* Use viewport height to ensure the .App container can stretch */\n/* } */\n\n/* .bodyContainer {\n    flex: 1; */\n     /* This allows the container to expand and fill available space */\n/*     display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start; */\n     /* Adjust this to flex-start to align content to the top */\n/*     max-width: 1200px;\n    width: 100%;\n    padding: 0;\n    margin: 0 auto;  */\n    /* Center the content horizontally */\n/* } */\n\n/* Add styles for the footer to ensure it doesn't collapse */\n/* footer {\n    width: 100%; */\n     /* Ensure the footer stretches across the bottom */\n    /* Add additional styling here for padding, background, etc. */\n/* }\n */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
